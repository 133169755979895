import type { MutableRefObject } from 'react';
import { useRef } from 'react';

export const useFocus = (): [MutableRefObject<any>, () => void] => {
  const htmlElRef = useRef(null);

  const setFocus = () => {
    setTimeout(() => {
      if (htmlElRef.current)
        htmlElRef.current?.focus();
    }, 400);
  };

  return [htmlElRef, setFocus];
};
