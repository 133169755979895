import type { AxiosRequestConfig } from 'axios';
import { postRequestBuilder, processCall } from '~/api/common/callApi';
import { env } from '~/const/env';
import { api, uriPrefix } from '~/const/api';
import { getSessionStorage, isSessionStorageEnabled } from '~/utils/utils';
import type { LocaleType } from '~/model/GlobalTypes';
import { SessionItem } from '~/const/appConst';

export const createToken = async (
  interactionId: number,
  userLocale: LocaleType,
) => {
  const request: AxiosRequestConfig<{ token: string }> = postRequestBuilder(
    `${env.REACT_APP_API}${api.GUEST_TOKEN}`,
    {
      interaction: `${uriPrefix}${api.INTERACTIONS}/${interactionId}`,
    },
    userLocale,
  );

  return processCall(request);
};

export const getGuestToken = (): string => {
  if (
    isSessionStorageEnabled()
    && getSessionStorage(SessionItem.GUEST_TOKEN) !== undefined
  ) {
    return getSessionStorage(SessionItem.GUEST_TOKEN);
  }
};
