import update from 'immutability-helper';
import { initialState } from './initialState';
import { actions } from '~/const/actions';

export const interaction = (
  state = initialState.interaction,
  action = { type: '', payload: null },
) => {
  switch (action.type) {
    case actions.GET_INTERACTION: {
      return { ...state, ...action.payload };
    }
    case actions.FLUSH_INTERACTION: {
      return null;
    }
    case actions.POST_SURVEY_ANSWERS: {
      return update(state, {
        contributors_count: { $set: state.contributors_count + 1 },
      });
    }
    case actions.POST_INTERACTION_REACTION: {
      const reactions = {
        dislike: state.reactions_repartition.dislike,
        like: state.reactions_repartition.like + 1,
      };
      return update(state, {
        reactions_repartition: { $set: reactions },
      });
    }
    case actions.DELETE_INTERACTION_REACTION: {
      const reactions = {
        dislike: state.reactions_repartition.dislike,
        like: state.reactions_repartition.like - 1,
      };
      return update(state, {
        reactions_repartition: { $set: reactions },
      });
    }
    default:
      return state;
  }
};
