import { initialState } from './initialState';
import { actions } from '~/const/actions';
import type { UserType } from '~/model/UserType';

export const authenticated = (
  state = initialState.authenticated,
  action: {
    type: string;
    authenticated?: boolean;
    user?: UserType;
  } = { type: '', authenticated: null, user: null },
) => {
  switch (action.type) {
    case actions.GET_AUTHENTICATED:
      return action.authenticated;

    // Set to true if user get defined
    case actions.GET_USER:
      return !!(action.user && action.user.id);

    // set to false after deleting account
    case actions.DELETE_USER:
      return false;

    default:
      return state;
  }
};

export const authenticationRefresh = (
  state = initialState.authenticationRefresh,
  action: {
    type: string;
    authenticationRefresh?: string;
    expirationDate?: string;
  } = { type: '', authenticationRefresh: null, expirationDate: null },
) => {
  switch (action.type) {
    case actions.GET_AUTHENTICATION_REFRESH:
      return action.authenticationRefresh;

    // Set when user get defined
    case actions.GET_USER:
      return action.expirationDate || state;

    default:
      return state;
  }
};
