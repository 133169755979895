export const dkconnect = {
  ACCESS_TOKEN: 'access_token',
  AUTHORIZATION_CODE: 'authorization_code',
  HAS_BEEN_CONNECTED: 'hasBeenConnected',
  REFRESH_TOKEN: 'refresh_token',
  PENDING_AUTH: 'pending-auth',
  SPORTS_HAS_BEEN_SUGGESTED: 'sportsHasBeenSuggested',
  TEST_REPORT: 'testReport',
  tokenFirebase: 'tokenFirebase',
};
