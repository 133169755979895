import { useTranslations } from 'next-intl';
import { Beardman404 } from '../Beardman/Beardman404/Beardman404';
import { MaintenanceClouds } from '@/app/[locale]/maintenance/components/MaintenanceClouds';

export const Maintenance = () => {
  const t = useTranslations('MAINTENANCE');
  return (
    <div className="maintenance-page">
      <div className="maintenance-page__wrapper">
        <div className="maintenance-page__message">
          <h1 className="vp-title-m">{t('TITLE')}</h1>
          <p className="vp-body-m">{t('MESSAGE')}</p>
          <Beardman404 />
          <MaintenanceClouds />
        </div>
      </div>
    </div>
  );
};
