export const actions = {
  // DISPLAY
  TOGGLE_DARK_MODE: 'TOGGLE_DARK_MODE',

  // NETWORK
  NETWORK_STATUS: 'NETWORK_STATUS',

  // COMMON
  ADD_HISTORY_URL: 'ADD_HISTORY_URL',
  UPDATE_HISTORY_URL: 'UPDATE_HISTORY_URL',
  SET_LOADING: 'SET_LOADING',
  SET_NAVIGATION_CODE: 'SET_NAVIGATION_CODE',

  // ERRORS
  ERROR: 'ERROR',
  FLUSH_ERRORS: 'FLUSH_ERRORS',
  GET_ERRORS: 'GET_ERRORS',
  GET_MAINTENANCE: 'GET_MAINTENANCE',

  // USER AND AUTHENTICATION
  DELETE_USER: 'DELETE_USER',
  DELETE_USER_CRITERIA: 'DELETE_USER_CRITERIA',
  GET_ACCOUNT: 'GET_ACCOUNT',
  GET_ACCOUNT_CONTACTS: 'GET_ACCOUNT_CONTACTS',
  GET_AUTHENTICATED: 'GET_AUTHENTICATED',
  GET_AUTHENTICATION_REFRESH: 'GET_AUTHENTICATION_REFRESH',
  GET_USER: 'GET_USER',
  GET_USER_CRITERIAS: 'GET_USER_CRITERIAS',
  POST_USER_CRITERIA: 'POST_USER_CRITERIA',
  POST_USER_CRITERIAS: 'POST_USER_CRITERIAS',
  UPDATE_USER_CRITERIAS: 'UPDATE_USER_CRITERIAS',
  PUT_USER: 'PUT_USER',
  PUT_USER_DATA: 'PUT_USER_DATA',
  GET_USER_INTERACTION_STATUS: 'GET_USER_INTERACTION_STATUS',
  UPDATE_USER_INTERACTION_STATUS: 'UPDATE_USER_INTERACTION_STATUS',
  FLUSH_USER_INTERACTION_STATUS: 'FLUSH_USER_INTERACTION_STATUS',

  // USER LOCALE
  SET_LOCALE: 'SET_LOCALE',

  // CRITERIAS
  GET_CRITERIAS: 'GET_CRITERIAS',
  GET_CRITERIA_CHOICES: 'GET_CRITERIA_CHOICES',
  FLUSH_CRITERIAS: 'FLUSH_CRITERIAS',
  FLUSH_USER_CRITERIAS: 'FLUSH_USER_CRITERIAS',
  GET_MAIN_CRITERIAS: 'GET_MAIN_CRITERIAS',

  // CHILDREN
  DELETE_CHILD: 'DELETE_CHILD',
  GET_CHILD: 'GET_CHILD',
  GET_CHILDREN: 'GET_CHILDREN',
  POST_CHILD: 'POST_CHILD',
  POST_CHILD_CRITERIA: 'POST_CHILD_CRITERIA',
  PUT_CHILD: 'PUT_CHILD',

  // SPORTS
  FLUSH_FILTER_SPORTS: 'FLUSH_FILTER_SPORTS',
  GET_SPORTS: 'GET_SPORTS',

  // STEP TYPES
  GET_STEP_TYPES: 'GET_STEP_TYPES',

  // Terms of uses
  GET_TERMS_OF_USES: 'GET_TERMS_OF_USES',

  // Interactions
  DELETE_INTERACTION_REACTION: 'DELETE_INTERACTION_REACTION',
  FLUSH_ANNOUNCEMENTS: 'FLUSH_ANNOUNCEMENTS',
  FLUSH_INTERACTION: 'FLUSH_INTERACTION',
  FLUSH_INTERACTIONS: 'FLUSH_INTERACTIONS',
  FLUSH_PROJECTS: 'FLUSH_PROJECTS',
  FLUSH_PROJECT: 'FLUSH_PROJECT',
  FLUSH_USER_PROJECTS: 'FLUSH_USER_PROJECTS',
  GET_INTERACTION: 'GET_INTERACTION',
  GET_PROJECT: 'GET_PROJECT',
  GET_PROJECTS: 'GET_PROJECTS',
  GET_PROJECTS_TAGS: 'GET_PROJECTS_TAGS',
  GET_HP_PROJECTS: 'GET_HP_PROJECTS',
  FLUSH_FOLLOWED_PROJECTS: 'FLUSH_FOLLOWED_PROJECTS',
  UPDATE_HP_FOLLOWED_PROJECTS: 'UPDATE_HP_FOLLOWED_PROJECTS',
  GET_HP_FOLLOWED_PROJECTS: 'GET_HP_FOLLOWED_PROJECTS',
  UPDATE_FOLLOWED_PROJECTS: 'UPDATE_FOLLOWED_PROJECTS',
  GET_FOLLOWED_PROJECTS: 'GET_FOLLOWED_PROJECTS',
  GET_TOP_PROJECTS: 'GET_TOP_PROJECTS',
  GET_USER_PROJECTS: 'GET_USER_PROJECTS',
  POST_INTERACTION_REACTION: 'POST_INTERACTION_REACTION',
  POST_SURVEY_ANSWERS: 'POST_SURVEY_ANSWERS',
  SET_SHOW_FILTER: 'SET_SHOW_FILTER',
  SET_SPORT_FILTER: 'SET_SPORT_FILTER',

  // Tests
  GET_TESTS: 'GET_TESTS',
  GET_TESTS_IN_MY_SPORTS: 'GET_TESTS_IN_MY_SPORTS',
  GET_TESTS_NOT_IN_MY_SPORTS: 'GET_TESTS_NOT_IN_MY_SPORTS',

  // Comments
  TOGGLE_AS_ADMIN: 'TOGGLE_AS_ADMIN',
};
