import type { GetServerSidePropsContext } from 'next';
import { parseStringPromise } from 'xml2js';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isDate from 'date-fns/isDate';
import decode from 'lean-he/decode';
import { animateScroll as scroll, scroller } from 'react-scroll';
import type { AxiosError } from 'axios';
import type { Dispatch } from 'react';
import type { MessageKeys } from 'next-intl';
import { apiConnect, getAuthenticationToken } from '~/api/apiConnect';
import { apiTypes, interactionStatus, uriPrefix } from '~/const/api';
import { env } from '~/const/env';
import type {
  BreakpointsTypeKeys,
} from '~/const/appConst';
import appConst, {
  Breakpoints,
  CookieName,
  breakpointsKeys,
  entityRoutes,
} from '~/const/appConst';
import type { CriteriaChoiceType } from '~/model/CriteriaChoiceType';
import type { InteractionType } from '~/model/InteractionType';
import type { QuestionReconstructedType, QuestionType } from '~/model/QuestionType';
import type { SportType } from '~/model/SportType';
import type {
  AnswerChoicesRankingScaleType,
  AnswerChoicesRankingType,
  AnswerUserChoicesValueType,
  AnswerUserType,
} from '~/model/AnswerUserType';
import type { ErrorResponseDataType, ErrorType } from '~/model/ErrorType';
import type { HistoryType } from '~/model/HistoryType';
import { getResponseData } from '~/api/common/callApi';
import type { UserType } from '~/model/UserType';
import type {
  OptionSportsType,
} from '~/components/Projects/model';
import {
  OptionsStatusValueType,
} from '~/components/Projects/model';
import type { LanguageType, LocaleType } from '~/model/GlobalTypes';
import { ThemeType } from '~/model/GlobalTypes';
import layout from '~/const/layout';
import type { ReduxStoreType } from '~/reducers/model';
import { checkSameDay } from '~/components/Header/utils';
import type { AnswerType } from '~/model/AnswerType';
import type { Messages } from '@/global';
import { acceptLanguages } from '@/navigation';
import type { LocaleCode } from '~/const/locale';

export const checkIsClientSideRouting = req =>
  !req || req.url?.startsWith('/_next/data');

export const getEnvApiBase = () => {
  return env.REACT_APP_API.replace(uriPrefix, '');
};

export const isWindow = () => typeof window !== 'undefined';

export const checkIsCrawler = () => {
  const ua = (isWindow() && window.navigator?.userAgent) || '';
  return ua.match(/Googlebot|Bingbot/i)?.length > 0;
};

// Prefered Color Scheme
export const isPreferedColorSchemeSupported = () =>
  isWindow()
  && window.matchMedia(layout.matchMedia.colorScheme).media !== 'not all';

const userPrefersDarkMode = () => {
  return (
    isPreferedColorSchemeSupported()
    && window.matchMedia(layout.matchMedia.dark).matches
  );
};

const userPrefersLightMode = () => {
  return (
    isPreferedColorSchemeSupported()
    && window.matchMedia(layout.matchMedia.light).matches
  );
};

export const getUserPreferedColorScheme = () => {
  if (!isPreferedColorSchemeSupported())
    return '';
  if (userPrefersDarkMode())
    return ThemeType.dark;
  if (userPrefersLightMode())
    return ThemeType.light;
  return '';
};

// Cookie
export const isCookieAvailable = () => isWindow() && window.document.cookie;

export const getCookie = (cookieName: string) => {
  if (!isCookieAvailable())
    return;
  const cookie = {};
  window.document.cookie.split(';').forEach((el) => {
    const [key, value] = el.split('=');
    cookie[key.trim()] = value;
  });
  return cookie[cookieName];
};

export const removeCookie = (name: string) => {
  window.document.cookie = `${name}=; Path=/; Max-Age=-99999999;`;
};

export const setCookie = (value: string, age = 31536000) => {
  window.document.cookie = `${value};path=/;max-age=${age};`;
};

// Local storage
export const isLocalStorageEnabled = () => isWindow() && window.localStorage;

export const getLocalStorage = (key: string) => {
  return isLocalStorageEnabled() && window.localStorage.getItem(key);
};

export const setLocalStorage = (key: string, value: string) => {
  return isLocalStorageEnabled() && window.localStorage.setItem(key, value);
};

export const removeLocalStorage = (key: string) => {
  return isLocalStorageEnabled() && window.localStorage.removeItem(key);
};

// Session storage
export const isSessionStorageEnabled = () =>
  isWindow() && window.sessionStorage;

export const getSessionStorage = (key: string) => {
  return isSessionStorageEnabled() && window.sessionStorage.getItem(key);
};

export const setSessionStorage = (key: string, value: string) => {
  return isSessionStorageEnabled() && window.sessionStorage.setItem(key, value);
};

export const removeSessionStorage = (key: string) => {
  return isSessionStorageEnabled() && window.sessionStorage.removeItem(key);
};

export const getLoginUri = (pathname = appConst.staticRoutes.index) => {
  const luckyParam = getSessionStorage('isLucky') ? '?luckyMember=true' : '';
  const stateParam = `&state=${encodeURIComponent(pathname)}${luckyParam}`;
  return `${env.REACT_APP_DK_CONNECT_AUTHORIZE}?response_type=code&client_id=${process.env.REACT_APP_DK_CONNECT_CLIENT_ID}&scope=${env.REACT_APP_DK_CONNECT_SCOPE}&redirect_uri=${env.REACT_APP_DK_CONNECT_REDIRECT_URI}${stateParam}`;
};

export const redirectToLogin = (pathname: string) => {
  window.location.href = getLoginUri(pathname);
};

export const getAccountVerificationUri = (currentRoute: HistoryType) => {
  const { as: asPath = appConst.staticRoutes.index } = currentRoute || {};
  setCookie(`${CookieName.BLOCKED_ACCOUNT_PATH}=${asPath}`);
  return `${env.ADMIN_URL}profile?state=${encodeURIComponent(asPath)}`;
};

export const redirectToAccountVerification = (currentRoute?: HistoryType) => {
  window.location.href = getAccountVerificationUri(currentRoute);
};

export const getLogoutUri = (token: string, state?: string) => {
  return `${env.REACT_APP_DK_CONNECT_LOGOUT}?id_token_hint=${
    token || getAuthenticationToken(CookieName.ACCESS_TOKEN)
  }&post_logout_redirect_uri=${env.REACT_APP_DK_CONNECT_REDIRECT_URI}&state=${
    state || 'logout'
  }`;
};

export const getResizedPictureUrl = (url: string, pictureFormat?: string) => {
  const baseUrl = url?.substring(0, url.lastIndexOf('/') + 1);
  const filename = url?.substring(url.lastIndexOf('/'));
  return baseUrl?.length && filename.length && pictureFormat
    ? `${baseUrl}${pictureFormat}${filename}`
    : url;
};

export const stripOutTags = (html = '', replacement = '') => {
  return (
    html
      // strip html tags
      .replace(/<.[^<>]*>/g, replacement)
      // replace less than and greater than characters
      // by corresponding html entities
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      // convert 3 consecutive dots by ellipsis
      .replace(/\.\.\./g, '… ')
      // add whitespace after punctuation
      // not followed by whitespace
      .replace(/\.(?=\S)/g, '. ')
      .replace(/,(?=\S)/g, ', ')
      .replace(/:(?=\S)/g, ': ')
      .replace(/;(?=\S)/g, '; ')
  );
};

export const extractDescription = (html = '', length = 155) => {
  // replace every tag by a space
  const stripped = (html && stripOutTags(decode(html), ' ')) || '';
  const text = stripped
    // trim first space
    .replace(/^\S*\s/, '')
    // convert consecutive spaces into one
    .replace(/\s+/g, ' ');

  return text.length > length ? `${text.substring(0, length)}...` : text;
};

export const convertLinebreaks = (text: string) => {
  return text?.replace(/\r\n|\r|\n/g, '<br/>') || '';
};

export const convertLinebreaksForMail = (text: string) => {
  return text?.replace(/\r\n|\r|\n/g, '\n') || '';
};

export const ellipsis = (text = '', limit = 60) => {
  return text && text.length > limit ? `${text.substring(0, limit)}...` : text;
};

// get the days left until a deadline
export const getLeftDays = (deadline: string) => {
  let leftDays = null;
  if (deadline != null)
    leftDays = differenceInDays(new Date(deadline), new Date());

  return leftDays;
};

// get the hours left until a deadline
export const getLeftHours = (deadline: string) => {
  return deadline ? differenceInHours(new Date(deadline), new Date()) : null;
};

// get the minutes left until a deadline
export const getLeftMinutes = (deadline: string) => {
  let leftMinutes = null;
  if (deadline !== null)
    leftMinutes = differenceInMinutes(new Date(deadline), new Date());

  return leftMinutes;
};

// get the minutes left until a deadline
export const addLeadingZero = (minutes: number) => {
  let leadingZero = minutes || minutes === 0 ? `${minutes}` : null;
  if (
    minutes !== null
    && minutes !== undefined
    && minutes < 10
    && minutes >= 0
  ) {
    leadingZero = `0${minutes}`;
  }

  return leadingZero;
};

export const getAbbrevNumber = (number: number) => {
  if (number > 10000)
    return '>10K';
  if (number > 999) {
    const decimal = (number / 1000).toFixed(1).replace(/[.,]0$/, '');
    return `${decimal}K`;
  }
  return `${number}`;
};

export const getResponseDataError = (
  res: AxiosError,
  rangeDown = 400,
  rangeUp = 600,
) => {
  const status = res?.response?.status;
  return status >= rangeDown && status <= rangeUp;
};

const isNetworkError = (res: AxiosError) => {
  return res?.message === 'Network Error' && !res.response;
};

interface GCPJsonErrorType {
  error: {
    code: string;
    details: string;
    message: string;
  };
}

export const buildErrorFromGCPError = (
  error: AxiosError,
  errorData: GCPJsonErrorType,
): AxiosError<ErrorType> => {
  const { code, details, message } = errorData.error;
  return {
    ...error,
    response: {
      ...error.response,
      statusText: code,
      data: { message: `${message} ${details}` },
    },
  };
};

export const convertXmlError = (
  error: AxiosError,
): Promise<AxiosError<ErrorType>> => {
  const errorData = error.response.data;
  const isXml = typeof errorData === 'string' && errorData.startsWith('<?xml');
  if (isXml) {
    return (
      parseStringPromise(errorData, {
        ignoreAttrs: true,
        explicitArray: false,
        trim: true,
        normalize: true,
        normalizeTags: true,
      })
        .then(json => buildErrorFromGCPError(error, json))
        // If the xml parser fail, return the initial error
        .catch(() => error)
    );
  } else {
    return Promise.resolve(error);
  }
};

export const getApiRouteFromUri = (url = '') =>
  url.replace(env.REACT_APP_API, '');

export const initError = ({
  res,
  message = null,
  isFileUpload = false,
  connect = false,
  timeslotFull = null,
}: {
  res: AxiosError<ErrorResponseDataType>;
  message?: string;
  isFileUpload?: boolean;
  connect?: boolean;
  timeslotFull?: boolean;
}): ErrorType => {
  const response = res?.response;
  const data = response?.data;
  const description
    = data?.['hydra:description'] || data?.message || data?.detail;
  const status = response?.status;
  const statusText = response?.statusText;
  const url = response?.config?.url && getApiRouteFromUri(response.config.url);

  if (getResponseDataError(res, 400, 400) && isFileUpload)
    message = 'ERROR_MESSAGE.NETWORK_504';

  // If a request fails before reaching the server (Network error)
  // and concerns a file upload
  // return a network message
  if (isNetworkError(res) && isFileUpload)
    return { message: 'ERROR_MESSAGE.TIMEOUT' };

  // If a request fails before reaching the server (Network error)
  // return a network message
  if (isNetworkError(res))
    return { message: 'ERROR_MESSAGE.NETWORK' };

  return {
    connect,
    description,
    message,
    status,
    statusText,
    timeslotFull,
    url,
  };
};

export const getIdFromUri = (uri: string) => {
  const array = uri && `${uri}`.split('/');
  const id = array?.[array.length - 1];
  return Number.isInteger(+id) ? +id : undefined;
};

export const formatIntegerValue = (value: string) => {
  // convert to integer if the value can be
  return (value || value === '0') && Number.isInteger(Number.parseInt(value, 10))
    ? +value
    : '';
};

export const formatScaleValue = (value: number | string) => {
  // convert to float if the value can be
  if (typeof value === 'string' && !Number.isNaN(Number.parseFloat(`${value}`)))
    return Number.parseFloat(`${value}`);
  else if (!value && value !== 0)
    return null;

  return value;
};

export const formatChoicesValue = (value: string | string[]) => {
  // convert the value to an array if it is not an array and not a date
  if (!Array.isArray(value) && !isDate(value)) {
    const arrayWrap: string[] = [];
    if (value)
      arrayWrap.push(value);

    return arrayWrap;
  }
  return value;
};

export const formatAnswerValue = (
  answerList: AnswerType[],
  answer: AnswerType,
  question: QuestionReconstructedType,
): {
  value?:
    | string
    | number
    | { choices: string[]; ranking_scale: string[] }
    | { choices: string[]; ranking: string[] };
  answer_choices?: string | string[] | Record<string, string | string[]>;
  media?: string;
} => {
  const formatedValue = {};

  switch (question.type) {
    case apiTypes.INTEGER:
      return { value: formatIntegerValue(`${answer.value_int}`) };
    case apiTypes.SCALE:
    case apiTypes.VAS1:
    case apiTypes.VAS2:
      return { value: formatScaleValue(answer.value_float) };
    case apiTypes.CHECK:
    case apiTypes.RADIO:
    case apiTypes.CONDITIONAL:
    case apiTypes.SELECT:
    case apiTypes.CHOICES_JAR:
      return {
        answer_choices: answer ? formatChoicesValue(answer.choices) : [],
        ...(answer?.value_text && { value_text: answer.value_text }),
      };
    case apiTypes.GRID:
      question.sub_questions.forEach((subquestion) => {
        const subAnswer = answerList?.find(
          previousAnswer => previousAnswer.sub_question === subquestion['@id'],
        );
        Object.assign(formatedValue, {
          [subquestion['@id']]: formatChoicesValue(subAnswer?.choices),
        });
      });
      return { answer_choices: formatedValue };
    case apiTypes.STAR_RATING:
      question.sub_questions.forEach((subquestion) => {
        const subAnswer = answerList?.find(
          previousAnswer => previousAnswer.sub_question === subquestion['@id'],
        );
        Object.assign(formatedValue, {
          [subquestion['@id']]: formatScaleValue(subAnswer?.value_float),
        });
      });
      return { answer_choices: formatedValue };
    case apiTypes.RANKING_PICTURE:
    case apiTypes.RANKING:
      return {
        answer_choices: { choices: answer.choices, ranking: answer.ranking },
      };
    case apiTypes.RANKING_SCALE:
      return {
        answer_choices: {
          choices: answer.choices,
          ranking_scale: answer.ranking_scale,
        },
      };
    case apiTypes.PICTURE:
    case apiTypes.FILE:
      return {
        value: answer.media['@id'],
        media: answer.media.content_url,
      };
    default:
      return { value: answer?.value_text || '' };
  }
};

export const getFormattedAnswerValue = (
  value: AnswerUserChoicesValueType,
  questionType: string,
) => {
  switch (questionType) {
    case apiTypes.INTEGER:
      return formatIntegerValue(value as string);
    case apiTypes.SCALE:
    case apiTypes.VAS1:
    case apiTypes.VAS2:
      return formatScaleValue(value as number);
    case apiTypes.CHECK:
    case apiTypes.RADIO:
    case apiTypes.CONDITIONAL:
    case apiTypes.SELECT:
    case apiTypes.GRID:
    case apiTypes.CHOICES_JAR:
      return formatChoicesValue(value as string | string[]);
    case apiTypes.RANKING_PICTURE:
    case apiTypes.RANKING:
      return {
        ...((value as AnswerChoicesRankingType).choices.length && {
          choices: (value as AnswerChoicesRankingType).choices,
        }),
        ...((value as AnswerChoicesRankingType).ranking.length && {
          ranking: (value as AnswerChoicesRankingType).ranking,
        }),
      };
    case apiTypes.RANKING_SCALE:
      return {
        ...{
          ...((value as AnswerChoicesRankingScaleType).choices.length && {
            choices: (value as AnswerChoicesRankingScaleType).choices,
          }),
          ...(Object.values(
            (value as AnswerChoicesRankingScaleType).ranking_scale,
          ).length && {
            ranking_scale: (value as AnswerChoicesRankingScaleType)
              .ranking_scale,
          }),
        },
      };
    default:
      return value;
  }
};

export const isEmpty = (obj: unknown) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const isAuthenticationError = (errors: ErrorType) =>
  !!errors && Object.values(errors).length > 0 && errors.status === 401;

export const isAccountBlocked = (status: number, description = '') =>
  status === 403 && description.includes('The account is blocked');

export const contributionDuplicate = (errors: ErrorType) =>
  errors.status === 400
  && (errors.description?.includes(appConst.apiErrors.duplicateContribution)
  || errors.description?.includes(
    appConst.apiErrors.duplicateContributionLongDurationSurvey,
  ));

export const missingMandatoryQuestion = (errors: ErrorType) =>
  errors.status === 400
  && errors.description.includes(appConst.apiErrors.missingMandatoryQuestion);

export const generateActionBtnError = (errors: ErrorType) => {
  if (errors && !isEmpty(errors)) {
    if (errors.connect === true && !errors.timeslotFull)
      redirectToLogin(null);
    else if (errors.connect === false && !errors.timeslotFull)
      window.location.href = appConst.staticRoutes.index;

    if (errors.timeslotFull === true && errors.connect === false)
      window.location.reload();
  }
};

export const generateContentMsgError = (
  errors: ErrorType,
): MessageKeys<
  Messages,
  | 'ERROR_MESSAGE.LOGIN'
  | 'ERROR_MESSAGE.BLOCKED'
  | 'ERROR_MESSAGE.AUTHORIZATION'
  | 'ERROR_MESSAGE.FORBIDDEN'
  | 'ERROR_MESSAGE.TIMESLOT_FULL'
  | 'ERROR_MESSAGE.DUPLICATE_CONTRIBUTION'
  | 'ERROR_MESSAGE.MISSING_MANDATORY_QUESTIONS'
  | 'ERROR_MESSAGE.GENERIC'
> => {
  if (errors && !isEmpty(errors)) {
    const { message, connect, status, description, timeslotFull } = errors;
    if (message)
      return message as any;
    if (connect === true)
      return 'ERROR_MESSAGE.LOGIN';
    if (isAccountBlocked(status, description))
      return 'ERROR_MESSAGE.BLOCKED';
    switch (status) {
      case 401:
        return 'ERROR_MESSAGE.AUTHORIZATION';
      case 403:
        return 'ERROR_MESSAGE.FORBIDDEN';
      default:
    }
    if (timeslotFull === true && connect === false)
      return 'ERROR_MESSAGE.TIMESLOT_FULL';
    if (contributionDuplicate(errors))
      return 'ERROR_MESSAGE.DUPLICATE_CONTRIBUTION';
    if (missingMandatoryQuestion(errors))
      return 'ERROR_MESSAGE.MISSING_MANDATORY_QUESTIONS';
    return 'ERROR_MESSAGE.GENERIC';
  }
};

export const generateTitleBtnError = (errors: ErrorType) => {
  if (errors && !isEmpty(errors)) {
    if (isAccountBlocked(errors.status, errors.description))
      return 'GLOBAL.VERIFY';
    if (errors.status === 401)
      return 'GLOBAL.LOGIN_2';
    if (errors.connect === true && !errors.timeslotFull)
      return 'GLOBAL.LOGIN_3';

    if (errors.connect === false && !errors.timeslotFull)
      return 'GLOBAL.HOME';

    if (errors.timeslotFull === true && errors.connect === false)
      return 'GLOBAL.GO_ON';
  }
};

// image uploader
// check if the file is an image
export const checkImageUploadAuthorizedFormat = (file) => {
  if (
    file.type.includes('image/jpeg')
    || file.type.includes('image/jpg')
    || file.type.includes('image/png')
    || file.type.includes('image/gif')
    || file.type.includes('image/webp')
  ) {
    return true;
  }

  return false;
};

export const checkWebpConversion = (file: Blob) => {
  if (file?.type === 'image/webp')
    return true;
  return false;
};

export const checkFileUploadAuthorizedFormat = (file) => {
  if (
    file.type.includes('image/jpeg')
    || file.type.includes('image/jpg')
    || file.type.includes('image/png')
    || file.type.includes('image/gif')
    || file.type.includes('image/webp')
    || file.type.includes('application/pdf')
    || file.name.split('.').pop() === 'gpx'
  ) {
    return true;
  }

  return false;
};

export const fileSizeMegaBytesDefaultLimit = 10;

// check if the file is not too heavy
export const checkFileUploadAuthorizedSize = (
  file,
  limit: number = fileSizeMegaBytesDefaultLimit,
) => {
  return file.size < limit * 1000000;
};

export const getYoutubeIdFromUrl = (url) => {
  const regExp
    = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url?.match(regExp);

  if (match?.[2].length === 11)
    return match[2];

  return null;
};

export const getRouteFromType = (type: string) => {
  return entityRoutes[type.toLowerCase()];
};

export const getFirstUnfilledMandatoryQuestionUri = (
  answerUser: AnswerUserType = {},
  questions: QuestionType[] = [],
) => {
  // Get user answers question uri
  const answersUri = Object.keys(answerUser);
  // get mandatory questions
  const mandatoryQuestions = questions.filter((q: any) => q.mandatory);
  // filter unfilled ones
  const unfilledQuestions = mandatoryQuestions.filter(
    // filter if the uri is not present among user answers
    (q: any) => {
      const uri = q['@id'];
      const type = q.type;
      return (
        !answersUri.includes(uri)
        // or if is present but value is falsy (but not 0)
        || (!answerUser[uri].value
        && answerUser[uri].value !== 0
        && answerUser[uri].value !== undefined)
        || (type === apiTypes.CHECK
        && answerUser[uri]
        && !answerUser[uri].value_text
        && (!answerUser[uri].answer_choices
        || (answerUser[uri].answer_choices as string[]).length < 1))
        || (q.sub_questions.length > 0
        && (!answerUser[uri].answer_choices
        || q.sub_questions.length
        !== Object.keys(answerUser[uri].answer_choices).length))
        || (type === apiTypes.SELECT
        && answerUser[uri]
        && (answerUser[uri].answer_choices as string[]).length !== 1)
        || (type === apiTypes.RADIO
        && answerUser[uri]
        && !answerUser[uri].value_text
        && !answerUser[uri].answer_choices)
      );
    },
  );
  // get the first unfilled question uri
  const firstUnfilledQuestionUri = unfilledQuestions.map(
    (q: any) => q['@id'],
  )[0];
  return firstUnfilledQuestionUri || null;
};

export const getRouteFallback = (state?: string): string => {
  if (state?.startsWith('/')) {
    // valid relative path
    return state;
  } else if (state?.includes(env.REACT_APP_DK_CONNECT_REDIRECT_URI)) {
    // absolute path from same dns
    return `${state.replace(env.REACT_APP_DK_CONNECT_REDIRECT_URI, '/')}`;
  } else if (state) {
    // incomplete path (ex : threads/8)
    return `/${state}`;
  } else {
    // no path, return /
    return appConst.staticRoutes.index;
  }
};

export const getAbsoluteRouteFromPath = (url = '', path = ''): string => {
  return `${url || ''}${
    path?.startsWith('/') ? path.replace('/', '') : path || ''
  }`;
};

export const getIdYoutube = (url) => {
  const regExp
    = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  if (match && match[2].length === 11)
    return match[2];

  return null;
};

export const getFloat = (number: string | number) => {
  if (number) {
    const numberToString = number.toString().replace(',', '.');
    if (Number.isNaN(Number.parseFloat(numberToString)))
      return null;

    return Number.parseFloat(numberToString);
  }
  return null;
};

export const reactScrollDefaultConfig = {
  duration: 400,
  offset: -80,
  smooth: true,
};

export const reactScrollConfigBumpy = {
  duration: 0,
  offset: 0,
  smooth: false,
};

export const scrollToTop = (
  config: Record<string, any> = reactScrollDefaultConfig,
) => {
  scroll.scrollToTop(config);
};

export const scrollToElement = (
  id: string,
  options: Record<string, any> = {},
) => {
  scroller.scrollTo(id, {
    ...reactScrollDefaultConfig,
    delay: 100,
    duration: 1000,
    ...options,
  });
};

export const capitalizeString = (value = '') =>
  value
    ? `${value?.[0].toUpperCase()}${value?.substring(1)?.toLowerCase()}`
    : '';

export const buildOptions = (list: Array<CriteriaChoiceType | SportType>) => {
  return list
    ? [...(list || [])].map((item) => {
        const label = capitalizeString(item.label);
        return {
          id: item.id,
          // As "label" can store a JSX value (favourite sports),
          label,
          // "title" serves as a plain text fallback
          title: label,
          value: item['@id'],
          ...((item as SportType).alias && {
            alias: (item as SportType).alias,
          }),
        };
      })
    : [];
};

export const roundToNDecimal = (number: number, nbDecimals = 1) => {
  const pow = 10 ** nbDecimals;
  return Math.round(number * pow) / pow;
};

export const checkIfInteractionIsFinished = (interaction: InteractionType) => {
  const status = interaction?.status;
  const now = Date.now();
  return (
    status !== undefined
    && (status === interactionStatus.trashed
    || isAfter(now, new Date(interaction?.end_at)))
  );
};

export const checkIfInteractionIsComing = (interaction: InteractionType) => {
  const status = interaction?.status;
  const now = Date.now();
  return status !== undefined && isBefore(now, new Date(interaction?.start_at));
};

type separatorType = '-' | '_';

export const convertLocaleSeparator = ({
  locale,
  separator = '_',
  newSeparator = '-',
}: {
  locale: LocaleType;
  separator?: separatorType;
  newSeparator?: separatorType;
}) => {
  return locale?.replace(separator, newSeparator) as LocaleType;
};

export const getLanguageFromLocale = (locale: LocaleType): LanguageType =>
  (locale?.length >= 2
  && (locale.includes('-') || locale.includes('_') || locale.length === 2)
  && locale?.split('-')[0].split('_')[0]) as LanguageType
  || undefined;

export const getLocaleFromLanguage = (
  language: LocaleCode,
  keepFormat = false,
) => {
  const locale = acceptLanguages.find(
    l => getLanguageFromLocale(l) === language,
  );
  return keepFormat ? locale : convertLocaleSeparator({ locale });
};

export const sumObjectValues = (obj: { [key: string]: number }) =>
  obj ? Object.values(obj).reduce((a, b) => a + b) : 0;

export const isUserDefined = (user: UserType | Record<string, any>) =>
  user !== null;

export const retrievedFilterValue = (
  value: OptionsStatusValueType,
  userId?: number | undefined,
): string => {
  switch (value) {
    case OptionsStatusValueType.user:
      return `&contributions_by_user=${userId}`;
    case OptionsStatusValueType.followed:
      return `&followed_up_by_user=${userId}`;
    case OptionsStatusValueType.recommended:
      return `&recommended_to_user=${userId || null}`;
    default:
      return '';
  }
};

export const getRequestLocaleCookieRedirection = ({
  req,
  language,
}: {
  req: Partial<GetServerSidePropsContext['req']>;
  language: LanguageType;
}): LanguageType => {
  const isCSR = checkIsClientSideRouting(req);
  const cookies = req?.cookies;
  const cookieLocale = cookies?.cocreation_locale as LocaleType;
  const cookieLanguage = getLanguageFromLocale(cookieLocale);
  if (!isCSR && cookieLanguage && cookieLanguage !== language)
    return getLanguageFromLocale(cookieLocale);

  return '' as LanguageType;
};

export const extractCookie = (value: string) => {
  return isWindow()
    ? window.document.cookie
      ?.split(';')
      ?.find(element => element.includes(value))
      ?.split('=')[1]
    : null;
};

export const handleLogout = async (
  setIsLoading: Dispatch<boolean>,
  throwErrorsDispatcher: (
    res: AxiosError,
    message?: string,
    isFileUpload?: boolean,
  ) => {
    errors: ErrorType;
    type: string;
  },
  t: any,
  currentRoute?: HistoryType,
) => {
  setIsLoading(true);
  // Refresh Token Before Logging out to avoid Dkt connect logout rejection
  try {
    const response = await apiConnect.refreshUserToken();
    const newToken = getResponseData(response)?.access_token;
    if (newToken) {
      !!currentRoute
      && setLocalStorage(
        appConst.localStorageKeys.redirectAfterLogout,
        JSON.stringify(currentRoute),
      );
      window.location.href = getLogoutUri(newToken);
    } else {
      setIsLoading(false);
      throwErrorsDispatcher(
        response as unknown as AxiosError,
        t('ERROR_MESSAGE.LOGOUT'),
      );
    }
  } catch (error) {
    setIsLoading(false);
    throwErrorsDispatcher(error, t('ERROR_MESSAGE.LOGOUT'));
    return error;
  }
};

export const isDecathlonian = (user: Partial<UserType>) =>
  user?.organization === appConst.organization.decathlon;

export const getChildFromId = (
  children: ReduxStoreType['children'],
  childId: number,
) => children?.find(c => c.id === childId);

export const buildLangValue = (
  fromLocale: LocaleType,
  toLocale: LocaleType,
) => {
  const fromLanguage = getLanguageFromLocale(fromLocale);
  const toLanguage = getLanguageFromLocale(toLocale);
  return (
    (!!fromLanguage
    && !!toLanguage
    && `${toLanguage}-x-mtfrom-${fromLanguage}`)
    || undefined
  );
};

export const defineLangValue = (
  originalLocale: LocaleType,
  userLanguage: LocaleType,
  entityTranslated?: boolean,
) => {
  return (
    (!entityTranslated
    && originalLocale !== userLanguage
    && buildLangValue(originalLocale, userLanguage))
    || null
  );
};

export const hasToCompleteSports = () => {
  const completedSportsDate = getLocalStorage('completed_sports_date');
  const completedSportsToday = checkSameDay(completedSportsDate);

  return !completedSportsDate || !completedSportsToday;
};

export const handleOptionSportsFilter = (
  sport: {
    label: string;
    value: string;
    data: OptionSportsType;
  },
  input: string,
) => {
  const normalizer = (value: string) =>
    value
      .normalize('NFD')
      .replace(/[\u0300-\u036F]/g, '')
      .toLowerCase();

  const normalizedInput = normalizer(input);

  return (
    sport.data.alias?.some(alias =>
      normalizer(alias).includes(normalizedInput),
    ) || normalizer(sport.data.title).includes(normalizedInput)
  );
};

export const matchMediaQuery = (
  setBreakpoint: (value: BreakpointsTypeKeys) => void,
) => {
  for (const key of Object.keys(Breakpoints)) {
    if (window.matchMedia(`${Breakpoints[key]}`).matches)
      setBreakpoint(key as BreakpointsTypeKeys);
  }
};

export const handleSBreakpoint = (breakpoint: BreakpointsTypeKeys) => {
  return breakpoint === breakpointsKeys.get(Breakpoints.S);
};

export const handleMBreakpoint = (breakpoint: BreakpointsTypeKeys) => {
  return breakpoint === breakpointsKeys.get(Breakpoints.M);
};

export const handleLBreakpoint = (breakpoint: BreakpointsTypeKeys) => {
  return breakpoint === breakpointsKeys.get(Breakpoints.L);
};

export const handleXLBreakpoint = (breakpoint: BreakpointsTypeKeys) => {
  return breakpoint === breakpointsKeys.get(Breakpoints.XL);
};

export const handleRedirectToLogin = (pathname: string) => {
  redirectToLogin(pathname);
};
