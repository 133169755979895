export const Logo = ({ isBrand = true }: { isBrand?: boolean }) => {
  return (
    <div className="cocreation-logo">
      <svg xmlns="http://www.w3.org/2000/svg" width={188} height={28} viewBox="0 0 188 28" fill="none">
        <title>Decathlon</title>
        <path d="M58 23.8h13.2v-4.3H63V16h7.2v-4H63V8.4h8.2V4.2H57.9v19.6zm29.6-8.4c-2 3-3.8 4.3-6.2 4.3-3 0-4.9-2.2-4.9-6 0-3.6 1.7-5.4 4.2-5.4 1.7 0 3.1.8 3.6 3.3h5c-.5-4.8-3.6-7.8-8.5-7.8-5.7 0-9.4 4-9.4 10.2 0 6.2 3.7 10.2 9.8 10.2 4 0 6.8-1.7 8.6-4h6.9v3.6h5V4.2h-7.1l-7 11.2zm9 1h-4.4L96.7 9v7.2zM47.3 4.1h-7.4v19.6h7.4c5.8 0 9.6-3.9 9.6-9.8 0-6-3.8-9.8-9.6-9.8zM47 19.5h-2.3v-11h2.3c2.9 0 4.5 2 4.5 5.5s-1.6 5.5-4.5 5.5zM159.5 3.8c-5.9 0-9.9 4-9.9 10.2 0 6.2 4 10.2 10 10.2 5.9 0 9.8-4 9.8-10.2 0-6.2-4-10.2-9.9-10.2zm0 15.9c-2.9 0-4.7-2-4.7-5.7s1.8-5.7 4.7-5.7c3 0 4.8 2 4.8 5.7s-1.8 5.7-4.8 5.7zM103 8.4h5v15.4h5V8.4h5V4.2h-15v4.2zm79.4-4.2V15l-6.5-10.8h-5.2v19.6h4.9V12.6l6.7 11.2h5V4.2h-4.9zm-39.8 0h-5v19.6H150v-4.2h-7.6V4.2zM131 11.7h-6.8V4.2h-5v19.6h5v-7.9h6.8v7.9h5V4.2h-5v7.5zM25.6 0C14.6 0 1 11.3 1 20.7 1 25.6 4.7 28 9.7 28c3.6 0 8-1.3 12.1-3.9V5.4c-1 2-6.4 9.7-10.6 13.8-2.2 2.1-3.9 3-5.4 3-1.6 0-2.4-1-2.4-2.8C3.4 12 16.2 2 24.6 2c3.5 0 5.8 1.5 5.8 4.5 0 2.8-2 6.3-5.1 9.4v5.8c5.6-4.3 8.9-10 8.9-14.5 0-4.7-3.7-7.2-8.6-7.2z" fill={isBrand ? 'var(--vp-semantic-color-content-brand)' : '#ffffff'} />
      </svg>
      <span>Co-creation</span>
    </div>
  );
};
