import { initialState } from './initialState';
import { actions } from '~/const/actions';

export const asAdmin = (
  state: boolean = initialState.asAdmin,
  action = { type: '', payload: null },
) => {
  if (action.type === actions.TOGGLE_AS_ADMIN)
    return action.payload;

  return state;
};
