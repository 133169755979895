export const Beardman404 = () => {
  return (
    <svg data-testid="beardman-404" xmlns="http://www.w3.org/2000/svg" width="312" height="312" viewBox="0 0 312 312" fill="none">
      <g clipPath="url(#a)">
        <path fill="#fff" d="M83.3 93.5l-16.5 4.4-6.5 5.5 1.6 19.2 8.8 6.6 20.8-6.6L90 115 86 98.4l-2.8-4.9z" />
        <path fill="#24286B" d="M75.9 106c3.1 2.5 7.3 3.3 11.1 2l-.3-.5-4.2 5c-.3.4.2 1 .5.6l4.2-5.1c.2-.3 0-.8-.4-.6-3.5 1.1-7.6.5-10.5-1.9-.3-.3-.8.2-.4.5zm7.2-8.9c0-.5.9 0 1 .4a83.2 83.2 0 012 3.5c0 .2.2.5.5.5.6.2 1-.6 1-1.3A13.5 13.5 0 0086 94" />
        <path fill="#fff" d="M58.9 121.2c-1.7-1-4.1-.3-5 1.5-.1.2-.2.6-.1.9 0 .3.2.5.4.6 1.1 1.1 2.7 1.6 4.2 1.2a4.5 4.5 0 003.2-3" />
        <path fill="#24286B" d="M59 120.9a4 4 0 00-5.4 1.8c-.3.7 0 1.3.4 1.9.4.4 1 .8 1.6 1a5 5 0 003.6.1 5 5 0 002.7-3c.2-.4-.5-.6-.7-.2a4 4 0 01-4.8 2.7l-1.3-.5c-.4-.3-1-.8-1-1.3 0-.8 1-1.6 1.7-2 1-.3 2-.3 2.8.1.5.2.9-.4.5-.6zm16.4 8.1a11 11 0 015.8-5c1.3-.4 2.5-.8 3.8-1 .9-.1 2.7-.5 3 .7l.2-.4a12.6 12.6 0 00-7.2 7c-.1.4.5.6.7.2a12 12 0 016.7-6.5c.3 0 .3-.2.3-.4-.6-2.2-4-1.2-5.4-.9-2.2.6-4.4 1.5-6.2 3-1 .9-1.8 1.9-2.4 3-.2.4.5.7.7.3zM176 213s-133.7-2.7-157 14c-11.5 8.2-.1 21 11 21.8 11.1.9 38.3-6.3 63.9-1.8 25.5 4.4 62-9.9 83.6-8.8 31.8 1.8 79.2 14.2 99.3 9.5 16.9-4 35.3-10.5 26.3-20.9-5.8-6.8-14.8-7.4-22.8-6.4-8.5 1-40.4 0-48-.1-21-.6-39.4-7.5-56.3-7.3z" />
        <path fill="#ABB2EB" d="M143 194.6c11.7-8.2 16-25.8 16-25.8l-.6-4.8c-10.6-24-43.3-22.7-43.3-22.7s-15 .7-45.1-5.4c-30.1-6.2-59.6 50.4-41.3 80.9 15.6 26 42.8 21.7 50.8 19.8a107.2 107.2 0 0034-1.2c5.2-1.9 9.2-6.3 14.3-8.5a71.6 71.6 0 0128.1-3.5c-6.4-12.2-7.6-16-13-28.8z" />
        <path stroke="#323894" strokeLinecap="round" d="M20.2 233.6c-.5 2.2-.4 7 4.4 8.8M198 226.1c-1.7-1.5-6-3.6-9.8-.2m11.5 4.2c.8.6 2.9 1.5 4.5-.2m9.8-1c1.8 1.5 6.2 3.4 9.8-.2m17.4 12c1.3 1.1 4.6 2.6 7.4-.1m-109.2-7.3c1.3 1.1 4.6 2.6 7.4-.1" />
        <path fill="#E1E0DF" d="M66 179.8a95.2 95.2 0 0116.5-5.8 94 94 0 0126.9-3.2c.4 0 .4-.8 0-.8-5.8 0-11.7.4-17.4 1.3a101.7 101.7 0 00-17.1 4.3 101.4 101.4 0 00-9.1 3.7c-.5.1-.2.7.3.5zm11 13.3a195.2 195.2 0 0132.8-4.7c3-.2 6.2-.3 9.3-.3.4 0 .4-.7 0-.7a190.8 190.8 0 00-42.3 5c-.5 0-.3.8.2.7z" />
        <path fill="#24286B" d="M61 125.7c-1 6.6 0 13.5 3 19.7.1-1.1.4-2.1.6-3.2 0 3.8 2.5 7.2 5.7 9.3 3.2 2 7.2 3 11.2 3.3 9.5.8 19.8-2 26.1-9 3.5-4 5.6-9.6 3.3-14.2-3.7-7.4-15.2-7.4-21.3-13.2-.6-.5-1.2-1.1-2-1.2-.7 0-1.2.2-1.8.5-1.2.4-2.5.4-3.7.1-1-.3-2-.8-3-.6-1.1.2-2 1.1-2.6 2a14.5 14.5 0 01-6.3 5.3c-2.6 1-6.2.2-7.3-2.4-1.4.4-2.4 2.1-1.9 3.6z" />
        <path fill="#24286B" d="M60 118.3a9 9 0 01-4.2-9.2 21 21 0 01-3-6.4c.3 1 1.6 1.3 2.6 1 .9-.4 1.6-1.2 2.3-2 6.9-8 16.8-13.4 27.3-14.6 2.7-.3 6.2 0 7.3 2.6.3.8.3 1.8-.4 2.3-.5.4-1.3.2-2 .2-1.7.3-2.6 1.9-3.7 3.1-1.1 1.2-2.5 2-4 2.7-4.4 2.1-9.6 3-14.5 2.5-1-.1-2-.3-2.8 0-2.6 1-2.5 4.5-2.1 7.2l1.7 9.5c.3 1.7.5 3.8-.9 4.9-1 .8-2.6.6-3.5-.4-.8-1-.7-2.6.3-3.5" />
        <path fill="#7982D9" d="M74 212c-2 1.2-2.4 4-2.4 6.2-.1 2.1-.2 4.2.4 6.2.5 1.6 1.5 3.1 2.4 4.5 1 1.5 1.9 2.9 3.1 4 1.3 1 3.1 1.7 4.7 1.3 2.4-.6 3.7-3.3 3.7-5.8 0-4.2-2.6-8-5.5-11-2-2.2-4.3-4.3-7-5.5" />
        <path fill="#fff" d="M74.4 225.8c0-2-.9-4-1.2-6.1-.7-3.8.3-7.9 2.8-10.9 2.4-3 6.1-5 10-5.1h.9l.8.6c2.9 3 5 6.6 6.3 10.5.6 1.8.8 4-.7 5.2-1.4 1-3.4.3-4.4-1-1-1.3-1.2-3-1.5-4.6.8 1.8 1.5 3.7 2 5.6.3 1 0 2.7-1 2.7-.6 0-1.1-.5-1.5-1l-2.4-3.2c1.2 1.2 1.9 3 1.8 4.6-.6.6-1.8.3-2.1-.4-.2 2.2-.5 4.7-2.3 5.9-1.4.8-3.1.6-4.6 0-1.4-.8-2.4-2.2-3.3-3.4" />
        <path fill="#24286B" d="M74.8 225.8c-.1-2.1-.9-4.1-1.3-6.2a14.7 14.7 0 01.3-5.6c.9-3.6 3.3-6.7 6.6-8.4 1.6-.9 3.4-1.4 5.3-1.6.5 0 1 0 1.4.3l1 1 2 2.3c1 1.6 2 3.3 2.8 5 .6 1.6 1.3 3.3 1.2 5 0 1-.3 1.8-1.2 2.3-.7.4-1.7.3-2.5-.2-2-1-2.4-3.5-2.7-5.4l-.6.3a34.7 34.7 0 011.5 3.8c.3 1 .7 2 .5 3.1 0 .4-.2.7-.6.9-.5.1-1-.4-1.3-.7l-2.5-3.3-.5.5a5.8 5.8 0 011.7 4.3v-.3c-.4.5-1.2.3-1.5-.3-.2-.4-.7-.2-.7.2-.1 2.1-.4 5.2-2.8 6-2.9.8-5.1-1.7-6.6-3.8-.3-.3-.8 0-.6.4 1.3 1.9 3 3.8 5.4 4.1 1.1.1 2.3 0 3.2-.8.8-.5 1.3-1.5 1.6-2.4.3-1.1.5-2.4.6-3.6l-.7.2c.3.6.9 1 1.5 1l.9-.2c.3-.3.5-.5.4-.8 0-1.7-.7-3.4-2-4.6-.3-.4-.7.1-.4.5l1.6 2 1.4 1.8c.9.7 2 .6 2.5-.4.5-1.2 0-2.4-.3-3.5a27.2 27.2 0 00-1.7-4.5c-.2-.5-.7-.1-.6.2.3 2 .6 4.1 2.3 5.5 1.3 1 3.3 1.4 4.5.1 1.2-1.2 1-3.2.7-4.8a24 24 0 00-2.5-5.7 24.4 24.4 0 00-3.9-5c-.3-.4-.7-.8-1.2-1-.5-.3-1-.2-1.5-.2-.9 0-1.9.3-2.8.5a14 14 0 00-10 14.2c0 2.7 1.3 5 1.3 7.7 0 .5.8.5.8 0zm22.3-6.4c.2 7.5 1.3 14.8 3.1 22 0 .2.1.5.4.7l.6.1 4.8-.3c.3 0 .6 0 .8-.3v-.7c-.4-3.2-1.7-6.1-2.6-9.2-1.6-5-2.4-10.2-2.3-15.4-2-.2-4 .4-5.5 1.5l.2.9m.9-16.3c1.8-29.1 8.8-57.9 20.5-84.6 2.9-6.6 6-13 9.9-19.2 7.4-11.8 17.4-22 28-31 7.2-6 14.8-11.6 23.2-15.8a77.4 77.4 0 0150.1-6c9.5 2 19.3 6.6 23.7 15.3a69.3 69.3 0 00-63-10.6A95.7 95.7 0 00155 73a173.4 173.4 0 00-50.3 104.9c-1 8.4-1.5 16.8-2 25.3-.1 1.9-.6 4.3-2.5 4.6-1.5.2-2.7-1-3.1-2.5a9 9 0 01.6-4.3" />
        <path fill="#24286B" d="M102.8 203.2c.3-16.2 3-32.2 7.5-47.7a186 186 0 0119.5-44A174.4 174.4 0 01161 73.5a83 83 0 0185.2-14 81.7 81.7 0 015.1 2.4c.4.2.8-.5.4-.7a82.5 82.5 0 00-44-8.2 82.4 82.4 0 00-41.6 15.4c-7 5.2-13.2 11.5-19 18.2a182.4 182.4 0 00-14.5 19.2 188.3 188.3 0 00-21.2 43.6 185 185 0 00-9.2 47.9 82.6 82.6 0 00-.2 5.9c0 .5.8.5.8 0z" />
        <path fill="#24286B" d="M252.4 62.1c.8.9-.2 2-1 2.6-.8.6-1.9 1-2.9 1.4-2 .6-4 .8-6 1.4-2.8.8-6.3 2.5-6.6 5.8-.3 2.5 1.2 5.5 4 5.3 2.2-.1 3.6-2.3 4.7-4 1-1.8 2.4-4.6 4.8-4.7 1.9-.1 3.3 1.4 5 2.1 1.3.7 2.8.6 4.3 0 3-1.3 5.2-4.7 6.5-7.6.7-1.6 1.2-3.4.5-5-.5-1.4-2-2.1-3.4-2.2-3.8 0-5.6 4-6.1 7-.4 2.1-.3 4.2.3 6.3.5 2 1.5 4.1 1.6 6.3.1 3.7-4 7.5-7.6 5.4-1.5-.9-2.5-2.7-2.3-4.4.1-1.3 1-2.5 2.1-3.2 4.2-2.6 10.7-.7 13.9-5 .8-1.3 1.2-3 .2-4.2-.9-1-2.4-1.3-3.7-1.2-1.7 0-3.5.6-5.1 1-1.8.4-3.6 1-5.2 1.7-3 1.5-5.6 4-6.5 7.2-.8 3-.5 6.2 1 9 3 5.4 10.6 6.4 15.5 2.7 1.1-.8 2.1-2 2.7-3.3.7-1.7 0-3.2-1.4-4.2a6.2 6.2 0 00-8.7 1.3c-1.8 2.7-1.1 5.8.7 8.3 1.8 2.2 4.2 4 5.4 6.6.6 1.2.7 2.8 0 4-1 1.5-3 2.2-4.6 1.7a4 4 0 01-2.5-4c0-2 1.5-3.2 3.2-3.7 1.6-.6 3.3-.9 5-1.4 1.6-.5 3-1.4 4.2-2.5 2.3-2.1 3.9-5.3 3.2-8.5a5.2 5.2 0 00-1.9-3.3c-1.4-1.1-3.1-.8-4.7-.3a38 38 0 00-10.8 5.3c-2.8 2-5 5.2-5.4 8.7a9 9 0 00.5 4.8c.7 1.6 1.9 2.9 3.2 4 1.4 1.2 3 2.4 4.1 4a13 13 0 012 5.8c.6 4.7 1 9.5 1.4 14.3l2.5 29 5.2 58.4 1.3 14.3c0 .4.8.4.7 0l-5-55.7-4.9-55.3c-.3-3.7-.3-8-2.6-11.1-2-2.9-5.6-4.4-7.2-7.7-1.4-3.3-.4-7.2 1.6-10 2.3-3.3 6-5.2 9.6-6.6l2.5-1c1.1-.4 2.2-1 3.4-1 1.7 0 3 1 3.5 2.6 1.1 3.2-.5 6.7-2.9 8.9-1.3 1.2-2.9 2-4.7 2.5-1.5.4-3.1.6-4.6 1.3-2.6 1.1-4 3.7-3 6.4 1.2 2.7 4.4 3.5 6.8 1.8 2.2-1.5 2.5-4.2 1.2-6.4-1.2-2.4-3.5-4-5.2-6.3a7.3 7.3 0 01-1.7-3.9c-.1-1.8.7-3.8 2.2-4.8a5.6 5.6 0 015.5-.5c.9.3 1.7 1 2.1 1.7.6 1.2.1 2.3-.6 3.2a9.9 9.9 0 01-7.7 4 9.2 9.2 0 01-8.1-3.7 11 11 0 01-1.5-9c1-3.5 3.6-5.9 6.8-7.3 1.7-.8 3.5-1.2 5.3-1.6 1.6-.4 3.3-1 5-.8.7.1 1.4.3 2 .8 1 .7.7 2 .2 3a6 6 0 01-2.8 2.4c-2.3 1-5 1.1-7.4 1.7-2.2.4-4.5 1.2-5.6 3.1-1.4 2.5-.3 5.5 2 7 2.6 1.6 6 .4 7.6-2 2.2-3.3.4-6.8-.5-10.2a15.2 15.2 0 01-.2-6.7 8 8 0 012.8-5.1c1.3-1 3.5-1.2 4.8 0 1.6 1.6.5 4.3-.4 6-1.3 2.9-3.8 6.7-7.3 7-3.1.3-5.2-3.4-8.4-2.4-2.1.6-3.3 2.8-4.3 4.5-1 1.7-2.4 4.1-4.7 4-1.5 0-2.5-1.3-2.9-2.7-.3-1.7.2-3.2 1.2-4.4 2.6-2.7 6.7-2.8 10-3.8 1.7-.4 3.4-1 4.6-2 .8-1 1.4-2.3.5-3.3-.2-.4-.7.1-.3.4z" />
        <path fill="#fff" d="M119 183.5l-1-4.4c-.9-4-3.9-10-3.9-10-.3-1.4-1-2.6-1.2-4.1-.2-2.2 1-4 1.3-6.2v-.7c-.5-.7-2.7.1-3.2.7-1.4 2.3-3.1 5.2-1.7 7.5-.2 0-6.8 2.6-10.5 4.6-.9.5-1.8 1-2.4 1.8-.7.8-1.1 1.8-.8 2.8 1 .4 2 .3 3.2.1l-.9.4c-.8.3-1.8.9-1.8 1.8-.1 1.2 1.5 1.7 2.7 1.7l2-.1c-1 .4-1.8 1-2.3 1.8-.5.8-.4 2.1.4 2.6.7.5 1.6.3 2.4 0 1-.2 2.1-.4 3.1-.8l-.5 3.3v1.4c.2.8 1 1.3 1.9 1.8 2.5 1.4 3.6 2.2 6.5 2.1 1.4-.9 2.7-2.1 4.9-5 .4-.4 2-2.3 1.8-3z" />
        <path fill="#24286B" d="M119.4 183.4c-.7-2.7-1.2-5.4-2.2-8-.7-2-1.6-4.1-2.6-6l-.2-.5-.8-2.3c-.3-.8-.5-1.5-.4-2.4 0-.9.3-1.6.5-2.5l.8-2.4c0-.5.2-1.3-.3-1.7-.8-.5-2.2.1-2.8.5-.6.3-.9.8-1.2 1.4a23 23 0 00-1.2 2.3c-.7 1.5-1 3.3-.1 4.8l.4-.5h-.6l-1.2.6-3.8 1.6a114 114 0 00-4.3 2c-1.5.7-3.2 1.6-3.9 3.2-.2.5-.3.9-.3 1.4 0 .4 0 .9.5 1 1 .5 2.2.4 3.2.1l-.3-.6c-.7.4-1.7.7-2.3 1.4-.5.5-.7 1.3-.2 2 1 1.6 3.3 1.3 4.9 1.1l-.3-.6c-1 .4-2 1-2.4 1.9-.4.8-.5 1.8 0 2.5.6.8 1.5 1 2.5.8 1.3-.2 2.6-.5 3.8-1l-.4-.5a60.8 60.8 0 00-.5 3.6c-.1 1 .1 1.9 1 2.6.6.6 1.6 1 2.4 1.5a8 8 0 004.8 1.4c.4 0 .6 0 1-.2l.8-.6c1.3-1 2.5-2.5 3.5-3.8l1.5-2c.3-.7.8-1.4.7-2.1 0-.4-.8-.3-.7.2 0 .5-.5 1.2-.7 1.5l-1.1 1.4c-1 1.2-2 2.6-3.3 3.7a6.8 6.8 0 01-1.4 1c-.3.2-.7.1-1 .1l-1.2-.1c-1.6-.4-3-1.2-4.2-2-.5-.2-.9-.5-1.2-.9-.4-.4-.5-1-.4-1.5 0-1.3.4-2.5.5-3.7 0-.2-.1-.6-.4-.4l-3 .8c-.7.3-1.9.5-2.5-.2-.4-.5-.4-1.4 0-2 .5-.8 1.3-1.2 2.1-1.5.4-.2 0-.8-.3-.7-1.1.2-3.1.5-4-.6-1-1.3 1.5-2.2 2.3-2.6.3-.1 0-.7-.3-.6a5 5 0 01-3-.1l.2.2c-.4-1.6 1-3 2.3-3.7a69.4 69.4 0 0110.6-4.9l.3-.1c.2 0 .3 0 0 0 .3 0 .6-.3.5-.6-.7-1.3-.5-2.8.1-4l1-2c.3-.5.5-1 1-1.3.4-.3 1-.5 1.4-.6h.6c.3 0 .2.2.2.5-.1 1.6-1 3-1.2 4.4-.3 1.6 0 3 .6 4.4l.8 2.1 2.5 5.6a48 48 0 012.2 8.3c0 .5.8.4.7-.1z" />
        <path fill="#fff" d="M90.8 211a8 8 0 014.7-6.1c2.5-1 5.6-.3 7.1 2a8 8 0 011.2 4.5c0 2.4-.7 4.8-2.5 6.3s-4.3 1.6-6.6 1.5c-1-.1-2.3-.3-3-1-.6-.6-.8-1.5-1-2.3a15.8 15.8 0 01.2-5.5" />
        <path fill="#24286B" d="M91.2 211c.5-2.6 2.2-5.1 4.9-6 2.4-.8 5.4.2 6.6 2.5.7 1.2.8 2.8.7 4.3 0 1.4-.3 2.8-1 4-1.6 2.7-4.8 3-7.6 2.9-.8-.1-1.7-.2-2.4-.5-.7-.5-1.1-1.3-1.2-2-.5-1.9-.4-4 0-5.8.1-.5-.5-.7-.7-.2a14 14 0 00-.1 5.7c.1.8.3 1.5.8 2.2.5.6 1.2 1 1.9 1 1.5.4 3.3.4 4.8.2 1.6-.2 3-.9 4.2-2 1.1-1.1 1.7-2.6 1.9-4.1.3-1.6.3-3.3-.2-4.9a5.8 5.8 0 00-2.7-3.4c-1.2-.7-2.7-1-4-.8-3.5.4-6 3.5-6.6 6.7-.1.4.6.6.7.2z" />
        <path fill="#24286B" d="M93.7 211.6c1.3-1.2 3-2 4.7-2.4.5 1 .2 2.1-.5 2.9a7 7 0 01-2.6 1.7l-3 1.5c-.8-1.8.3-4.1 2.1-4.6" />
        <path fill="#fff" d="M97.4 205c2.4-.4 5.3-.7 7 1 .5.7.9 1.6 1.2 2.4.7 1.9 1.3 4 .7 6-.9 3.1-4.7 5-7.7 3.8l-.3-.2c-.3-.3 0-.8.2-1.1 1.5-2 3-4.2 2.8-6.6-.2-2.3-2-4.1-4-5.1" />
        <path fill="#24286B" d="M97.5 205.4c2.4-.4 5.6-.8 7 1.6.8 1.2 1.3 2.7 1.6 4.2.2 1.4.2 2.9-.5 4.2a5.8 5.8 0 01-5.7 2.8c-.3-.1-1.6 1-2 .8 0 0-.3 0-.4-.2-.1-.2 0-.3.2-.5.3-.6 2-2.3 2.4-3a9.3 9.3 0 001.6-3.8c.4-3-1.6-5.5-4-6.7-.5-.2-.8.4-.4.7 1.3.6 2.4 1.5 3.1 2.8a5 5 0 01.3 4.4c-.5 1.5-2.6 3.8-3.5 5.1-.4.5-.6 1.2 0 1.7.4.3 2.2-.7 2.8-.6 2.4.3 5-1 6.2-3 .8-1.3 1-2.8.8-4.2a16.3 16.3 0 00-1.4-4.7c-.8-1.5-2-2.3-3.5-2.6-1.5-.3-3.1 0-4.7.2-.5.1-.3.9.1.8zm-25.2 5.1c-2.7 2-6.3.2-9-1.1-3-1.5-5.8-3.2-7.6-6-3.3-5.2-2.8-12-1.3-17.6 1-4 2.5-7.9 4.5-11.5a.4.4 0 00-.6-.3 51 51 0 00-4.4 10.5 33 33 0 00-1.5 9.9c.1 3 .7 6.2 2.3 9 1.7 3 4.5 4.8 7.5 6.3s7.2 3.7 10.3 1.4c.5-.3.2-.9-.2-.6zM164.4 65c1 1.6 2.3 3 3.9 4 .3 0 .5-.2.5-.5-.4-2-2-3.8-4-4.3-.4-.1-.7.6-.2.7 1.8.5 3.2 2 3.5 3.8l.5-.4c-1.5-1-2.7-2.2-3.6-3.7-.2-.4-.8 0-.6.4zm35.8-16a76 76 0 012.4 2.5l1.2 1.2c.3.4.7.6.8 1.1.2.3 0 .8-.3.7-.4 0-.7-.4-1-.7-.7-.7-1-1.6-1.5-2.5-.5-1-1-2-1.2-3.2-.2-.4-.8-.2-.7.3a18.8 18.8 0 001.2 3.3 31.8 31.8 0 001.8 3c.7.5 1.9 1 2.3 0 .5-1.1-.7-2-1.4-2.7l-3.2-3.3c-.2-.5-.7 0-.4.4zm-59 40.7c1.7-.3 3.6.3 4.8 1.7l.3-.7c-2 .1-4-.5-5.7-1.5-.4-.3-.8.3-.4.6 1.8 1.2 4 1.7 6 1.7.3 0 .5-.4.3-.7a6 6 0 00-5.5-2c-.5.3-.3 1 .1.9zM120.7 124c.4 0 .4-.7 0-.7s-.4.7 0 .7z" />
        <path fill="#24286B" d="M120.4 124a5.2 5.2 0 013.8 2v-.6c-.3.3-.8.1-1.1-.2l-1-1.1c-.7-.7-2-1.4-2.7-.5-.3.3.2.8.5.5.3-.3.7-.2 1 0 .5.3.8.7 1.1 1.1.6.7 1.7 1.5 2.6.9.2-.2.2-.4 0-.6-1-1.3-2.6-2-4.2-2.1-.4 0-.4.7 0 .7zm132-63c-.7.6-.8 1.4-.5 2.1 0 .2.3.2.5.2.3-.2.6-.6.7-1 0-.4 0-.8-.3-1.2-.1-.2-.4-.2-.5 0-.2.2-.2.4 0 .5V62.6c-.1.1 0 0 0 0l-.1.1c-.2.1 0 0 0 0h-.2v.1l.5.2v-.7-.2c0-.1 0 0 0 0V62h.1v-.2h.1l.2-.2a.3.3 0 000-.5c-.2-.2-.4-.2-.6 0zm-15.8 166c6.6-2.8 14.3-3 21.1-.6 3.4 1.3 6.5 3.2 10 4 1.7.5 3.4.5 5.2.2 1.9-.2 3.7-1 5.4-1.7 4-1.6 7.8-3.7 11.4-6 .4-.2 0-.8-.3-.6-3.3 2-6.6 4-10.2 5.5-1.8.8-3.6 1.6-5.4 2-1.9.4-3.7.5-5.6 0-3.5-.7-6.5-2.6-9.9-3.9a27.2 27.2 0 00-10.4-1.8c-4 0-8 .8-11.6 2.5-.5 0-.2.7.3.5zm49.7-45.2v1.2h.3c.2 0 .4-.1.4-.3v-.9l-.1-.3h-.3c-.1 0-.4.1-.4.3z" />
        <path fill="#ABB2EB" d="M263.3 216.4c2.8-.8 5.3 1 6.3 3.8 1.4 4.3-.2 9.6-4 12.3 2.5.3 4.6 2.3 5 4.7-1.7 0-3-1.8-4.7-1.6-.5.2-1.1.5-1.5.9l-4.8 3.8c1-3 2.8-5.7 5.2-7.7-3-1.6-5.2-4.9-5.5-8.4-.3-3.5.6-5.8 3.4-7.9" />
        <path fill="#D8DBF5" d="M259.7 221.2c1 1.5 3.2 2.1 4.9 2a5 5 0 004.1-2.7c.2-.3.3-.6.3-1 0-.3-.1-.6-.3-.8a5 5 0 00-5.5-2.3c-2 .5-3.5 2.6-3.5 4.8" />
        <path fill="#24286B" d="M259.4 221.4c1 1.5 2.8 2.1 4.5 2.2 1.8 0 3.6-.7 4.7-2.2.5-.8.9-1.7.5-2.7a5 5 0 00-1.7-1.9 5.2 5.2 0 00-5-.5 5.3 5.3 0 00-3.1 4.9c0 .4.7.4.7 0 0-1.5.7-3 2-3.9 1.3-.9 2.9-1 4.2-.4a5 5 0 011.7 1.3c.2.3.5.7.6 1.1.1.5 0 1-.4 1.4a4.7 4.7 0 01-3.9 2.2c-1.5 0-3.4-.4-4.3-1.8-.1-.4-.7 0-.5.3z" />
        <path fill="#D8DBF5" d="M270.1 223a10 10 0 013.8 3l-4 .3" />
        <path fill="#24286B" d="M270 223.3c1.4.7 2.7 1.7 3.6 3l.3-.6-4 .2c-.5 0-.5.8 0 .8l4-.3c.2 0 .5-.2.3-.5a11 11 0 00-4-3.3c-.3-.2-.6.5-.2.7zM160.5 57.5a85 85 0 0112.3-9 88 88 0 0113.7-6.9c2.7-1 5.4-1.9 8.2-2.6.4-.1.2-.8-.3-.7a85.8 85.8 0 00-14.4 5.3A88.8 88.8 0 00160 57c-.3.4.2.9.5.6zm3.1-10a52.5 52.5 0 0118-8.8c.4-.1.2-.8-.3-.7a54.9 54.9 0 00-14.3 6.4 42.8 42.8 0 00-3.7 2.5c-.4.3 0 1 .3.7zM269.4 58a8 8 0 01.8 5.9c-.1.4.6.6.7.2.6-2.2.2-4.5-.9-6.4-.2-.4-.9 0-.6.3zm3.3 4.7c0 1.8-.6 3.6-1.6 5-.2.2 0 .5.1.6.2.1.4 0 .5-.2a8.8 8.8 0 001.7-5.4c0-.5-.7-.5-.7 0zM25.1 157.3a27 27 0 00-4.2 9.9c-.1.4.5.6.7.2.8-3.5 2.2-6.8 4.2-9.6.2-.5-.4-.8-.7-.5zm35.1-53c-1.3 3.7-.6 7.9.2 11.7l1.2 6.6c.4 1.7.6 3.4 1.3 5 .6 1.4 1.7 2.6 3.2 2.8 1.6.2 3.2-.6 4.4-1.7 2.2-2 3.5-4.8 6.3-6.1 1-.5 2-.6 3.2-.7l3.7-.1 7.5-.3c.2 0 .4-.2.4-.4-.7-4.2-1.7-8.3-2.7-12.4a94.5 94.5 0 00-1.7-6c-.5-1.7-1-3.5-2.1-5-2-2.6-5.5-2.7-8.6-2.3A45.5 45.5 0 0066 98.2c-3.2 1.7-6 4.8-6 8.5 0 .5.8.5.8 0 0-3.2 2.2-5.9 4.8-7.5 3-1.7 6.7-2.4 10.2-3 2.8-.5 6.4-.7 8.4 1.6 1.2 1.3 1.6 3.2 2.1 4.8a171 171 0 014.6 18.6l.3-.4-6.5.3c-2 0-4.1 0-6.2.3-1.5.2-2.8.8-4 1.9-1.1 1-2 2-2.9 3.2-.9 1-1.9 2.1-3.1 2.8-.9.4-1.9.7-2.8.4-.7-.2-1.2-.7-1.6-1.3-.9-1.2-1.2-2.8-1.5-4.3a194.9 194.9 0 01-1.2-6.3c-.8-4.3-2-9-.5-13.2.2-.6-.5-.8-.7-.4z" />
        <path fill="#ABB2EB" d="M113.8 141.1c11.4.5 23.1 1.9 32.6 8.1 9.5 6.2 15.9 18.4 12.2 29.2a26.3 26.3 0 01-7.6 10.7 38 38 0 01-45.2 2.8c8.5.2 14.4-6.8 14.9-15.4 6.4-.5 14.7-5 18-10.7" />
        <path fill="#24286B" d="M21.4 214a74.7 74.7 0 01-3.6-12.8 77.2 77.2 0 01-1.3-13.4c0-2.5 0-5 .3-7.5 0-.4-.6-.4-.7 0a75.3 75.3 0 000 13.5 78 78 0 004.7 20.5c.2.4.7.1.6-.3zm-7.1-5.6a46.1 46.1 0 01-1.8-17.4c0-.4-.5-.5-.6 0a48.2 48.2 0 00.8 13.7 38 38 0 001 3.8c0 .4.7.3.6-.1zm106.3-27l-.1-.2h-.1v.1l.2.1zm13.7-7.7l.2.3.3-.2-.2-.2-.3.1zm-12.5-11.3a.3.3 0 10-.2.6l.2-.6zM107 193.8c3.3-.8 10.8-4.2 13.9-12.3l-.6-.2a20.2 20.2 0 01-13.5 12l.2.5zm13.8-12.1l13.7-7.7-.3-.5-13.7 7.7.3.5zm13.8-8.1c-1-1.5-2.7-3.8-4.9-6s-5-4.3-7.9-5.2l-.2.6a20 20 0 017.7 5 34 34 0 014.8 5.9l.5-.3z" />
        <path fill="#24286B" d="M142.7 195.4a.3.3 0 10-.2-.5l.2.5zm-36-1.6a42.5 42.5 0 0036 1.6l-.2-.5a41.9 41.9 0 01-35.4-1.7l-.3.6z" />
      </g>
      <defs><clipPath id="a"><path fill="#fff" d="M1 38h304v211H1z" /></clipPath></defs>
    </svg>
  );
};
