export function MaintenanceClouds() {
  return (
    <svg
      className="maintenance-clouds"
      width="403"
      height="272"
      viewBox="0 0 403 272"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M266.408 25.077c-.141-2.787 5.459-4.974 11.27-5.898 5.776-.924 12.08-1.02 17.856-1.977 12.89-2.155 20.427-8.037 28.95-12.93 2.747-1.556 5.811-3.111 9.931-3.824 3.099-.535 6.551-.535 9.826-.292 6.163.454 12.08 1.799 16.201 3.922 4.472 2.317 6.656 5.411 11.023 7.76 4.12 2.22 6.903 3.242 13.172 3.744 4.085.324 11.164 1.863 12.044 2.187 7.467 2.739 6.797 6.87 5.53 9.528-4.367 9.09-11.024 9.965-12.221 10.37-7.009 2.43-11.27.81-20.11.73a2974.325 2974.325 0 0 1-38.001-.6 2336.526 2336.526 0 0 1-32.048-.924c-7.502-.275-15.003-.583-22.294-1.393-2.289-.26-4.578-.567-6.48-1.2-2.782-.94-4.332-2.495-4.825-4.05-.493-1.556-.105-3.144.282-4.715M116.191 11.574c-.066-1.286 2.55-2.296 5.265-2.722 2.698-.426 5.643-.471 8.341-.913 6.021-.994 9.542-3.709 13.524-5.967 1.283-.718 2.714-1.436 4.639-1.765 1.448-.247 3.06-.247 4.591-.135 2.879.21 5.643.83 7.568 1.81 2.089 1.07 3.109 2.498 5.149 3.582 1.925 1.025 3.225 1.496 6.153 1.728 1.909.15 5.216.86 5.627 1.01 3.488 1.263 3.176 3.17 2.583 4.397-2.04 4.195-5.149 4.599-5.709 4.786-3.274 1.121-5.265.374-9.394.336-5.923-.052-11.829-.15-17.752-.276-5.002-.113-9.987-.247-14.972-.427-3.504-.127-7.009-.269-10.414-.643-1.07-.12-2.139-.262-3.028-.553-1.299-.434-2.023-1.152-2.254-1.87-.23-.718-.049-1.45.132-2.176M102.693 261.29c.106-2.144-4.104-3.826-8.473-4.537-4.342-.71-9.082-.785-13.424-1.521-9.691-1.657-15.358-6.182-21.765-9.946-2.066-1.196-4.37-2.393-7.467-2.941-2.33-.412-4.925-.412-7.388-.225-4.633.349-9.082 1.384-12.18 3.017-3.362 1.782-5.004 4.163-8.287 5.97-3.098 1.707-5.19 2.493-9.903 2.879-3.071.249-8.394 1.433-9.055 1.683-5.614 2.106-5.11 5.285-4.158 7.329 3.284 6.992 8.288 7.665 9.188 7.977 5.27 1.869 8.473.623 15.12.561 9.531-.088 19.037-.25 28.57-.462 8.049-.187 16.071-.411 24.094-.71 5.64-.212 11.28-.449 16.761-1.072 1.721-.199 3.442-.436 4.872-.922 2.092-.723 3.257-1.92 3.627-3.116.371-1.197.08-2.418-.211-3.627"
        fill="#B4D8F2"
        fillOpacity=".2"
      />
    </svg>
  );
}
