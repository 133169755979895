import { actions } from '~/const/actions';
import { getResponseDataError } from '~/utils/utils';

const setMaintenanceMode = (maintenance: boolean) => ({
  maintenance,
  type: actions.GET_MAINTENANCE,
});

const checkMaintenanceStatus = (response, dispatch) => {
  if (getResponseDataError(response, 502, 503))
    dispatch(setMaintenanceMode(true));
};

export const actionsMaintenance = {
  checkMaintenanceStatus,
  setMaintenanceMode,
};
