import type { CriteriaChoiceUserType } from './CriteriaChoiceType';
import type { LocaleType, UriType } from './GlobalTypes';
import type { MediaType } from './MediaType';
import type { SportType } from './SportType';
import type { RoleTypes, UserRewardsStatus } from '~/const/appConst';

export enum Gender {
  MALE = 'Homme',
  FEMALE = 'Femme',
}

export enum UserTypeEnum {
  USER = 'User',
  GUEST_USER = 'GuestUser',
}

export interface UserType {
  '@id'?: string;
  '@type'?: UserTypeEnum;
  address?: string;
  admin?: boolean;
  avatar_color?: string;
  blocked_account?: boolean;
  borned_at?: string;
  child?: boolean;
  city?: string;
  criterion_choice_users?: CriteriaChoiceUserType[];
  digest_mail_accepted?: boolean;
  favorite_locale?: LocaleType;
  firstname?: string;
  gender?: Gender;
  guest?: boolean;
  id?: number;
  internal?: boolean;
  interaction?: UriType;
  job?: string;
  label?: string;
  lastname?: string;
  last_connection_at?: string;
  mail?: string;
  media?: MediaType;
  notification_authorizations?: string[];
  organization?: string;
  origin?: string;
  phone?: string;
  roles?: RoleTypes[];
  sports?: SportType[];
  status?: UserRewardsStatus;
  stream_user_id?: string;
  term_of_use_accepted?: boolean;
  zipcode?: string;
}
