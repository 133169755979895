import { actions } from '~/const/actions';
import type { ErrorType } from '~/model/ErrorType';

export const throwErrorsAction = (errors: ErrorType) => ({
  errors,
  type: actions.GET_ERRORS,
});

export const flushErrorsAction = () => ({
  errors: {},
  type: actions.FLUSH_ERRORS,
});

export const actionsErrors = {
  throwErrorsAction,
  flushErrorsAction,
};
