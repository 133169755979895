export const isClient = typeof window !== 'undefined';

// Get the value from the according client/server
const getConstant = (constant, obfuscated) =>
  isClient ? (window as any)[obfuscated] : process.env[constant];

export const env = {
  BYPASS_AUTHENTICATION_TOKEN: getConstant(
    'BYPASS_AUTHENTICATION_TOKEN',
    'b_a_t',
  ),
  DISABLED_ERRORS_MSG: getConstant('DISABLED_ERRORS_MSG', 'd_e_m'),
  IMAGES_DOMAIN: getConstant('IMAGES_DOMAIN', 'i_d'),
  MAINTENANCE_MODE: getConstant('MAINTENANCE_MODE', 'm_m'),
  MIGRATION_MODE: getConstant('MIGRATION_MODE', 'mi_m'),
  MIGRATION_URL: getConstant('MIGRATION_URL', 'mi_u'),
  ENVIRONMENT: getConstant('ENVIRONMENT', 'env'),
  REACT_APP_API: getConstant('REACT_APP_API', 'c_c_a'),
  REACT_APP_API_VERSION: getConstant('REACT_APP_API_VERSION', 'c_c_a_v'),
  REACT_APP_DK_CONNECT_AUTHORIZE: getConstant(
    'REACT_APP_DK_CONNECT_AUTHORIZE',
    'd_c_a',
  ),
  REACT_APP_DK_CONNECT_LOGOUT: getConstant(
    'REACT_APP_DK_CONNECT_LOGOUT',
    'd_c_l',
  ),
  REACT_APP_DK_CONNECT_REDIRECT_URI: getConstant(
    'REACT_APP_DK_CONNECT_REDIRECT_URI',
    'd_c_r',
  ),
  REACT_APP_DK_CONNECT_SCOPE: getConstant(
    'REACT_APP_DK_CONNECT_SCOPE',
    'd_c_s',
  ),
  SHARE_FACEBOOK_TWITTER: getConstant('SHARE_FACEBOOK_TWITTER', 's_f_t'),
  ADMIN_URL: getConstant('ADMIN_URL', 'a_u'),
  COCREATION_URL: getConstant('COCREATION_URL', 'c_u'),
  ABOUT_FOOTER_VIDEO_URL_FR: getConstant(
    'ABOUT_FOOTER_VIDEO_URL_FR',
    'a_f_v_u_f',
  ),
  ABOUT_FOOTER_VIDEO_URL_INTER: getConstant(
    'ABOUT_FOOTER_VIDEO_URL_INTER',
    'a_f_v_u_i',
  ),
  DATADOG_RUM_APP_ON: getConstant('DATADOG_RUM_APP_ON', 'd_r_a_o'),
  DATADOG_RUM_SES_RP: getConstant('DATADOG_RUM_SES_RP', 'd_r_s_r'),
};

export default env;
