import type { Dispatch } from '@reduxjs/toolkit';
import { processDispatch } from './common/dispatcher';
import { getResponseData } from '~/api/common/callApi';
import { apiInteractions } from '~/api/apiInteractions';
import { actions } from '~/const/actions';
import type { LocaleType } from '~/model/GlobalTypes';
import type { InteractionType } from '~/model/InteractionType';

export const getInteraction = data => ({
  payload: data,
  type: actions.GET_INTERACTION,
});

export const getInteractionAction = (id: number, locale: LocaleType) => {
  return (dispatch: Dispatch): Promise<InteractionType> => {
    return processDispatch({
      dispatch,
      request: apiInteractions.getInteraction(id, locale),
      action: getInteraction,
      response: getResponseData,
    });
  };
};

export const setSportFilterAction = (value) => {
  return (dispatch) => {
    dispatch({
      payload: value,
      type: actions.SET_SPORT_FILTER,
    });
  };
};

export const flushInteraction = () => ({
  type: actions.FLUSH_INTERACTION,
});

export const flushInteractions = () => ({
  type: actions.FLUSH_INTERACTIONS,
});

export const flushFilterSports = () => ({
  type: actions.FLUSH_FILTER_SPORTS,
});

export const flushAnnouncements = () => ({
  type: actions.FLUSH_ANNOUNCEMENTS,
});

export const actionsInteractions = {
  flushInteraction,
  flushInteractions,
  getInteraction,
  getInteractionAction,
};
