import update from 'immutability-helper';
import type { AxiosPromise, AxiosRequestConfig } from 'axios';
import {
  getDataCollection,
  getRequestBuilder,
  getResponseData,
  getSubRouteFromUri,
  getUnpaginatedCollection,
  processCall,
} from './common/callApi';
import { api, apiPaths, translationsQueryParam } from '~/const/api';
import { env } from '~/const/env';
import type {
  OptionSportsType,
  OptionsStatusValueType,
} from '~/components/Projects/model';
import { retrievedFilterValue } from '~/utils/utils';
import type { ProjectTagType } from '~/model/ProjectTag';
import type { ProjectType } from '~/model/ProjectType';
import appConst from '~/const/appConst';
import type { SportType } from '~/model/SportType';
import type { LocaleType } from '~/model/GlobalTypes';
import type { DataCollectionType } from '~/model/DataCollectionType';
import type { InteractionType } from '~/model/InteractionType';

interface GetProjectsProps {
  statusFilter?: OptionsStatusValueType;
  pageUrl?: string;
  sportsFilters?: OptionSportsType[];
  userId?: number;
  sorting?: boolean;
  itemsPerPage?: number;
  locale?: LocaleType;
}

const getProjects = ({
  statusFilter,
  pageUrl,
  sportsFilters,
  userId,
  itemsPerPage,
  sorting,
  locale,
}: GetProjectsProps): AxiosPromise<DataCollectionType<ProjectType>> => {
  const organizationParam = `organization.name=${appConst.organizationName}`;

  const filterSorting = sorting
    ? '&active_order_by_interaction_start_at=desc'
    : '';

  const filterStatus = retrievedFilterValue(statusFilter, userId);

  const filterItemsPerPage = itemsPerPage
    ? `&itemsPerPage=${itemsPerPage}`
    : '';

  const filterSports = sportsFilters
    ? sportsFilters
      .map(sport =>
        sport.mysports
          ? sport.mysports.map(
            favoriteSport => `&sports[]=${favoriteSport.id}`,
          )
          : `&sports[]=${sport.id}`,
      )
      .toString()
      .replaceAll(',', '')
    : '';

  const request: AxiosRequestConfig<DataCollectionType<ProjectType>> = pageUrl
    ? getRequestBuilder(
        `${env.REACT_APP_API}${getSubRouteFromUri(pageUrl)}`,
        false,
        locale,
    )
    : getRequestBuilder(
        `${env.REACT_APP_API}${api.PROJECTS}?${organizationParam}${filterSorting}${filterStatus}${filterItemsPerPage}${filterSports}`,
        false,
        locale,
    );

  return processCall(request);
};

const getProject = (
  id: number,
  locale: LocaleType,
): AxiosPromise<ProjectType> => {
  const request: AxiosRequestConfig<ProjectType> = getRequestBuilder(
    `${env.REACT_APP_API}${api.PROJECTS}/${id}?${translationsQueryParam(
      apiPaths.PROJECT,
    )}`,
    false,
    locale,
  );
  return processCall(request);
};

const getProjectInteractions = ({
  projectId,
  locale,
  ongoing,
  pageUrl,
}: {
  projectId: number;
  locale: LocaleType;
  ongoing?: boolean;
  pageUrl?: string;
}): AxiosPromise<DataCollectionType<InteractionType>> => {
  const activeParam = ongoing ? '&state=active' : '&state=active-and-closed';
  const serializationGroups = `&groups[]=read_project_interactions&${translationsQueryParam(
    apiPaths.INTERACTION,
  )}`;

  const queryParams = `step.project=${projectId}&order[startAt]=DESC${serializationGroups}&displayAsNews=false${activeParam}&itemsPerPage=10`;

  const request: AxiosRequestConfig<DataCollectionType<InteractionType>>
    = getRequestBuilder(
      `${env.REACT_APP_API}${
        pageUrl
          ? getSubRouteFromUri(pageUrl)
          : `${api.INTERACTIONS}?${queryParams}`
      }`,
      false,
      locale,
    );

  return processCall(request);
};

const getProjectTags = (
  projectsIds: number[],
): AxiosPromise<DataCollectionType<ProjectTagType>> => {
  const filterIds = projectsIds.map(id => `project_id[]=${id}`).join('&');
  const request: AxiosRequestConfig<DataCollectionType<ProjectTagType>>
    = getRequestBuilder(`${env.REACT_APP_API}${api.PROJECT_TAGS}?${filterIds}`);
  return processCall(request, (response): any => {
    const data = getResponseData(response);
    const tags: ProjectTagType[] = getDataCollection(data);
    // Get fetched tags' project ids
    const tagsIds = tags?.map(t => t.project_id) ?? [];
    // and filter missing ids from the requested ones
    const missingTagsIds = projectsIds.filter(id => !tagsIds.includes(id));
    // forge missing tags
    const forgedTags: ProjectTagType[]
      = missingTagsIds?.map(id => ({
        project_id: id,
        interaction_id: 0,
        interaction_type: null,
        interaction_tag: null,
      })) ?? [];
    const updatedData = update(data, {
      'hydra:member': { $set: [...tags, ...forgedTags] },
    });
    return { ...response, data: updatedData };
  });
};

export const getCrossSellProjects = (
  projectId: number,
  locale: LocaleType,
) => {
  const request: AxiosRequestConfig<DataCollectionType<ProjectType>> = getRequestBuilder(
      `${env.REACT_APP_API}${api.PROJECTS}/${projectId}/${api.MATCHING_USER_CRITERIA}?${translationsQueryParam(
        apiPaths.PROJECT,
      )}`,
      false,
      locale,
  );
  return processCall(request);
};

const getUnpaginatedProjects = (
  locale: LocaleType,
  queryParams = 'order[endAt]=ASC',
  noAuthorization = false,
): Promise<ProjectType[]> => {
  return getUnpaginatedCollection({
    route: api.PROJECTS,
    queryParams,
    noAuthorization,
    acceptLanguage: locale,
  });
};

const getUnpaginatedProjectSports = (
  projectId: number,
): Promise<SportType[]> => {
  return getUnpaginatedCollection({
    route: `${api.PROJECTS}/${projectId}/${api.SPORTS}`,
  });
};

export const apiProjects = {
  getProject,
  getProjects,
  getProjectInteractions,
  getProjectTags,
  getUnpaginatedProjects,
  getUnpaginatedProjectSports,
};

export default apiProjects;
