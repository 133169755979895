import update from 'immutability-helper';
import { initialState } from './initialState';
import { getDataCollection } from '~/api/common/callApi';
import { actions } from '~/const/actions';
import type { ProjectType } from '~/model/ProjectType';
import type { ProjectTagType } from '~/model/ProjectTag';

export const projectTags = (
  state = initialState.projectTags,
  action: { type: string; payload: ProjectTagType[] } = {
    type: '',
    payload: null,
  },
) => {
  const { type, payload } = action;
  if (type === actions.GET_PROJECTS_TAGS) {
    // Concatenate new array to the current one
    const concatenated = state ? [...state, ...(payload || [])] : payload;
    // Return depuplicated tag array
    return concatenated.reduce((acc, val) => {
      const isAlreadyListed = acc.find(s => s.id === val.project_id);
      if (!isAlreadyListed)
        acc.push(val);
      return acc;
    }, []);
  }
  return state;
};

const buildFollowedProjectState = (state, action) => {
  if (action.active) {
    const toUpdateIndex = state?.findIndex(
      p => p?.['@id'] === action.payload?.['@id'],
    );
    if (toUpdateIndex === -1 || !toUpdateIndex) {
      // add it to the list
      return state ? [...state, action.payload] : [action.payload];
    }
  } else {
    // find project to delete index
    const toDeleteIndex = state?.findIndex(
      p => p?.['@id'] === action.payload?.['@id'],
    );
    // and remove it
    if (toDeleteIndex > -1)
      return update(state, { $splice: [[toDeleteIndex, 1]] });
  }
  return state;
};

export const projectsData = (
  state = initialState.projectsData,
  action = { type: '', payload: null },
) => {
  switch (action.type) {
    case actions.GET_PROJECTS: {
      const data = state === null ? [] : getDataCollection(state);
      const payloadCollection = getDataCollection(action.payload) || [];
      return {
        ...action.payload,
        'hydra:member': [...data, ...payloadCollection],
      };
    }
    case actions.FLUSH_PROJECTS: {
      return null;
    }
    default:
      return state;
  }
};

export const hpProjectsData = (
  state = initialState.projectsData,
  action = { type: '', payload: null },
) => {
  if (action.type === actions.GET_HP_PROJECTS)
    return action.payload || [];
  else return state;
};

export const hpFollowedProjectsData = (
  state = initialState.projectsData,
  action: {
    type: string;
    payload: ProjectType[];
    active?: boolean;
  } = { type: '', payload: null },
) => {
  switch (action.type) {
    case actions.GET_HP_FOLLOWED_PROJECTS: {
      return action.payload || [];
    }
    case actions.UPDATE_HP_FOLLOWED_PROJECTS: {
      return buildFollowedProjectState(state, action);
    }
    default:
      return state;
  }
};

export const hpTopProjects = (
  state = initialState.hpTopProjects,
  action = { type: '', payload: null },
) => {
  if (action.type === actions.GET_TOP_PROJECTS)
    return action.payload || [];
  return state;
};

export const followedProjectsData = (
  state = initialState.projectsData,
  action = { type: '', payload: null },
) => {
  switch (action.type) {
    case actions.GET_FOLLOWED_PROJECTS: {
      const data = state === null ? [] : getDataCollection(state);
      const payloadCollection = getDataCollection(action.payload) || [];
      return {
        ...action.payload,
        'hydra:member': [...data, ...payloadCollection],
      };
    }
    case actions.FLUSH_FOLLOWED_PROJECTS: {
      return null;
    }
    default:
      return state;
  }
};

export const userProjectsData = (
  state = initialState.projectsData,
  action = { type: '', payload: null },
) => {
  switch (action.type) {
    case actions.GET_USER_PROJECTS: {
      const data = state === null ? [] : getDataCollection(state);
      const payloadCollection = getDataCollection(action.payload) || [];
      return {
        ...action.payload,
        'hydra:member': [...data, ...payloadCollection],
      };
    }
    case actions.FLUSH_USER_PROJECTS: {
      return null;
    }
    default:
      return state;
  }
};

export const project = (
  state: ProjectType = initialState.project,
  action: {
    type: string;
    payload: ProjectType;
  } = { type: '', payload: null },
) => {
  switch (action.type) {
    case actions.GET_PROJECT: {
      return action.payload || state;
    }
    case actions.FLUSH_PROJECT: {
      return null;
    }
    default:
      return state;
  }
};
