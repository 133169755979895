import Slider from 'rc-slider/lib/Slider';
import { useLayoutEffect } from 'react';
import type { HandleChangeAnswerType } from '../model';
import { apiTypes } from '~/const/api';
import type { AnswerUserType } from '~/model/AnswerUserType';
import {
  calculateMarks,
  calculateNumberOfMarks,
  calculateSliderMarks,
} from '~/components/Interaction/utils';
import { getIdFromUri } from '~/utils/utils';
import { useFocus } from '~/hooks/useFocus';

export const isRangeDividableByStep = (
  min: number | undefined,
  max: number | undefined,
  step: number | undefined,
) => {
  if (min === undefined || max === undefined || step === undefined)
    return false;

  const range = max - min;
  return range % step === 0;
};

export const getMarks = (numberOfMarks: number, step: number, min: number) => {
  // loop through the number of marks to populate the marks object
  // Ex : {0: "0", 2: "2", 4: "4", 6: "6", -6: "-6", -4: "-4", -2: "-2"}
  return [...Array(numberOfMarks + 1).keys()].reduce((acc, key) => {
    // add limit_min in case it is negative :
    // (start from this negative minimum limit)
    const markValue = key * step + min;
    return { ...acc, [markValue]: `${markValue}` };
  }, {});
};

export const ScaleTypeQuestion = ({
  answerUser,
  questionId,
  limit_min: limitMin,
  limit_max: limitMax,
  step: scaleStep,
  label_min: labelMin,
  label_middle: labelMiddle,
  label_max: labelMax,
  handleChangeAnswer,
  langValue,
  disabled = false,
}: ScaleTypeQuestionPropsType) => {
  const [handleRef, setHandleFocus] = useFocus();
  const scaleSliderCanBeRendered
    = isRangeDividableByStep(limitMin, limitMax, scaleStep) || scaleStep === 0;

  const numberOfMarks = calculateNumberOfMarks(
    scaleSliderCanBeRendered,
    scaleStep,
    limitMin,
    limitMax,
  );

  const marks = calculateMarks(
    scaleSliderCanBeRendered,
    numberOfMarks,
    scaleStep,
    limitMin,
  );

  const sliderMarks = calculateSliderMarks(
    scaleSliderCanBeRendered,
    marks,
    limitMin,
    limitMax,
  );

  const defaultValue
    = scaleSliderCanBeRendered && limitMin !== undefined && limitMax !== undefined
      ? (limitMax + limitMin) / 2
      : undefined;

  const handleId = `question-${getIdFromUri(questionId)}`;

  useLayoutEffect(() => {
    setHandleFocus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleRef.current]);

  return (
    <div data-testid="scale">
      {
        // hide labels if step is zero
        scaleStep !== 0 && (
          <div className="vas__labels">
            <span lang={langValue}>{labelMin}</span>
            <span lang={langValue}>{labelMiddle}</span>
            <span lang={langValue}>{labelMax}</span>
          </div>
        )
      }
      {scaleSliderCanBeRendered && (
        <div className="vas__slider">
          <Slider
            className={`SCALE ${!answerUser[questionId] ? 'unanswered' : ''}`}
            defaultValue={defaultValue}
            value={
              answerUser[questionId] != null
                ? (answerUser[questionId].value as number)
                : defaultValue
            }
            min={limitMin}
            max={limitMax}
            marks={sliderMarks}
            step={scaleStep}
            onChange={e => handleChangeAnswer(e, questionId, apiTypes.SCALE)}
            handleRender={node => (
              <div
                {...node.props}
                style={node.props.style}
                id={handleId}
                ref={handleRef}
              />
            )}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  );
};

export interface ScaleTypeQuestionPropsType {
  answerUser: AnswerUserType;
  questionId: string;
  limit_min: number;
  limit_max: number;
  step: number;
  label_min: string;
  label_middle: string;
  label_max: string;
  handleChangeAnswer: HandleChangeAnswerType;
  langValue: string;
  disabled?: boolean;
}
