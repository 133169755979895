import { initialState } from './initialState';
import { actions } from '~/const/actions';
import type { HistoryType } from '~/model/HistoryType';

export const historyUrl = (
  state: HistoryType[] = initialState.historyUrl,
  action: { type: string; payload: HistoryType | HistoryType[] } = {
    type: '',
    payload: null,
  },
): HistoryType[] => {
  switch (action.type) {
    case actions.ADD_HISTORY_URL: {
      // check if the route is not already the last of the state
      // in order to avoid consecutive duplicates
      const isAlreadyLast
        = state
        && state.length
        && (action.payload as HistoryType).as
        && (action.payload as HistoryType).as === state[state.length - 1].as;
      return isAlreadyLast ? state : [...state, action.payload as HistoryType];
    }
    case actions.UPDATE_HISTORY_URL: {
      return (action.payload as HistoryType[]) || state;
    }
    default:
      return state;
  }
};
