import { actions } from '~/const/actions';
import type { HistoryType } from '~/model/HistoryType';

const addHistoryUrl = (href: string, as: string) => ({
  payload: { href, as },
  type: actions.ADD_HISTORY_URL,
});

const updateHistoryUrl = (history: HistoryType[]) => ({
  payload: history,
  type: actions.UPDATE_HISTORY_URL,
});

export const actionsHistoryUrl = {
  addHistoryUrl,
  updateHistoryUrl,
};
