import Head from 'next/head';
import React from 'react';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { Provider } from 'react-redux';
import { NextIntlClientProvider, useLocale } from 'next-intl';
import { ErrorDialog } from '~/components/commons/ErrorDialog/ErrorDialog';
import { Layout } from '~/components/commons/Layout/Layout';
import { PageLoader } from '~/components/commons/PageLoader/PageLoader';
import { Footer } from '~/components/Footer/Footer';
import { wrapper } from '@/store';
import '@fontsource/inter/latin-500.css';
import '@fontsource/inter/latin-600.css';
import '@vtmn-play/design-tokens/foundations';
import '@vtmn-play/css/utilities';
import '~/assets/scss/style.scss';
import { SkipLink } from '~/components/commons/SkipLink/SkipLink';
import appConst from '~/const/appConst';
import { useBreakpoint } from '~/hooks/match-media/useBreakpoint';
import { Medallia } from '~/components/ThirdParty/Medallia';
import { Datadog } from '~/components/ThirdParty/Datadog';
import { defaultTranslationValues } from '@/i18n';
import { defaultLocale, usePathname } from '@/navigation';

const DynamicHeader = dynamic(
  () => import('~/components/Header/Header').then(res => res.Header),
  { ssr: true },
);

const isServerSideRendered = () => {
  return typeof window === 'undefined';
};

if (process.env.NODE_ENV !== 'production' && !isServerSideRendered()) {
  import('react-dom').then((ReactDOM) => {
    import('@axe-core/react').then((axe) => {
      axe.default(React, ReactDOM, 1000, {});
    });
  });
}

const MyApp = ({ Component, pageProps }: AppProps) => {
  const { store } = wrapper.useWrappedStore(pageProps);

  const {
    profile,
    settings,
    messages,
    unsubscribe,
  } = appConst.staticRoutes;

  const pathname = usePathname();
  const locale = useLocale();
  const isPathnameUnsubscribe = pathname === unsubscribe;

  const { isMBreakpointMin } = useBreakpoint();

  const headerDisplayed
    = (isMBreakpointMin && !isPathnameUnsubscribe)
    || ![
      profile,
      settings,
      messages,
      unsubscribe,
    ].includes(`/${pathname.split('/')[1]}`);

  return (
    <Provider store={store}>
      <NextIntlClientProvider
        locale={locale ?? defaultLocale}
        timeZone="Europe/Paris"
        messages={pageProps.messages}
        defaultTranslationValues={defaultTranslationValues}
      >
        <Layout>
          <SkipLink />
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0"
              key="viewport"
            />
          </Head>
          {headerDisplayed && <DynamicHeader />}
          <main className="main-content" id="maincontent" role="main">
            <Component {...pageProps} />
          </main>
          <Footer />
        </Layout>
        <ErrorDialog />
        <PageLoader />
        <Datadog />
        <Medallia />
      </NextIntlClientProvider>
    </Provider>
  );
};

export default MyApp;
