import { initialState } from './initialState';
import { actions } from '~/const/actions';

export const errors = (
  state: any = initialState.errors,
  action = { type: '', errors: null },
) => {
  switch (action.type) {
    case actions.GET_ERRORS:
      return action.errors ? action.errors : state;
    case actions.FLUSH_ERRORS:
      return action.errors;
    default:
      return state;
  }
};
