import { initialState } from './initialState';
import { actions } from '~/const/actions';
import type { ThemeType } from '~/model/GlobalTypes';

export const layout = (
  state: any = initialState.layout,
  action: { type: string; payload: ThemeType } = {
    type: '',
    payload: null,
  },
) => {
  if (action.type === actions.TOGGLE_DARK_MODE) {
    return {
      ...state,
      theme: action.payload,
    };
  }
  return state;
};
