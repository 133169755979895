import { notFound } from 'next/navigation';
import { getRequestConfig } from 'next-intl/server';
import type { RichTranslationValues } from 'next-intl';
import { locales } from './navigation';

export const getIntlMessages = async (locale: string) => ({
  ...(await import(`@/public/static/locales/${locale}/common.json`)).default,
});

export const defaultTranslationValues: RichTranslationValues = {
  important: chunks => <strong>{chunks}</strong>,
  italic: chunks => <i>{chunks}</i>,
  paragraph: chunks => <p>{chunks}</p>,
  br: () => <br />,
};

export default getRequestConfig(async ({ locale }) => {
  // Validate that the incoming `locale` parameter is valid
  if (!locales.includes(locale as any))
    notFound();

  return {
    defaultTranslationValues,
    messages: await getIntlMessages(locale),
    timeZone: 'Europe/Paris',
  };
});
