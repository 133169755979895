import type { Dispatch } from '@reduxjs/toolkit';
import { processDispatch } from './common/dispatcher';
import {
  getResponseData,
  getResponseDataCollection,
} from '~/api/common/callApi';
import { apiProjects } from '~/api/apiProjects';
import { actions } from '~/const/actions';
import type { ProjectType } from '~/model/ProjectType';
import type {
  OptionSportsType,
} from '~/components/Projects/model';
import {
  OptionsStatusValueType,
} from '~/components/Projects/model';
import type { ProjectTagType } from '~/model/ProjectTag';
import type { LocaleType } from '~/model/GlobalTypes';
import { api } from '~/const/api';

const flushProject = () => ({
  type: actions.FLUSH_PROJECT,
});

const flushProjects = () => ({
  type: actions.FLUSH_PROJECTS,
});

const updateHpFollowedProjects = (project: ProjectType, active: boolean) => ({
  type: actions.UPDATE_HP_FOLLOWED_PROJECTS,
  payload: project,
  active,
});

const updateFollowedProjects = (project: ProjectType, active: boolean) => ({
  type: actions.UPDATE_FOLLOWED_PROJECTS,
  payload: project,
  active,
});

const flushFollowedProjects = () => ({
  type: actions.FLUSH_FOLLOWED_PROJECTS,
});

const flushUserProjects = () => ({
  type: actions.FLUSH_USER_PROJECTS,
});

const getProjects = payload => ({
  payload,
  type: actions.GET_PROJECTS,
});

const getProjectsAction = (
  locale: LocaleType,
  sportsFilters?: OptionSportsType[],
  page?: string,
) => {
  return (dispatch: Dispatch) => {
    return processDispatch({
      dispatch,
      request: apiProjects.getProjects({
        sportsFilters,
        pageUrl: page,
        sorting: true,
        locale,
      }),
      action: getProjects,
      response: getResponseData,
    });
  };
};

const getProjectTags = (payload: ProjectTagType[] = []) => ({
  payload,
  type: actions.GET_PROJECTS_TAGS,
});

const getProjectTagsAction = (projectsIds: number[]) => {
  return (dispatch: Dispatch) => {
    return processDispatch({
      dispatch,
      request: apiProjects.getProjectTags(projectsIds),
      action: getProjectTags,
      response: getResponseDataCollection,
    });
  };
};

const getHpProjects = payload => ({
  payload,
  type: actions.GET_HP_PROJECTS,
});

const getHpProjectsAction = (
  locale: LocaleType,
  sportsFilters?: OptionSportsType[],
  page?: string,
) => {
  return (dispatch: Dispatch) => {
    return processDispatch({
      dispatch,
      request: apiProjects.getProjects({
        sportsFilters,
        pageUrl: page,
        sorting: true,
        locale,
      }),
      action: getHpProjects,
      response: getResponseDataCollection,
    });
  };
};

const getHpFollowedProjects = payload => ({
  payload,
  type: actions.GET_HP_FOLLOWED_PROJECTS,
});

const getHpFollowedProjectsAction = (
  userId,
  locale: LocaleType,
  page?: string,
) => {
  return (dispatch: Dispatch) => {
    return processDispatch({
      dispatch,
      request: apiProjects.getProjects({
        statusFilter: OptionsStatusValueType.followed,
        pageUrl: page,
        userId,
        sorting: true,
        itemsPerPage: 5,
        locale,
      }),
      action: getHpFollowedProjects,
      response: getResponseDataCollection,
    });
  };
};

const getFollowedProjects = payload => ({
  payload,
  type: actions.GET_FOLLOWED_PROJECTS,
});

const getFollowedProjectsAction = (
  userId,
  locale: LocaleType,
  sportsFilters?: OptionSportsType[],
  page?: string,
) => {
  return (dispatch: Dispatch) => {
    return processDispatch({
      dispatch,
      request: apiProjects.getProjects({
        statusFilter: OptionsStatusValueType.followed,
        sportsFilters,
        pageUrl: page,
        userId,
        sorting: true,
        locale,
      }),
      action: getFollowedProjects,
      response: getResponseData,
    });
  };
};

const getTopProjects = (payload: ProjectType[]) => ({
  payload,
  type: actions.GET_TOP_PROJECTS,
});

const getTopProjectsAction = (locale: LocaleType) => {
  return (dispatch: Dispatch) => {
    return processDispatch({
      dispatch,
      request: apiProjects.getProjects({
        pageUrl: `${api.PROJECTS}?order_top_projects`,
        locale,
      }),
      action: getTopProjects,
      response: getResponseDataCollection,
    });
  };
};

const getUserProjects = payload => ({
  payload,
  type: actions.GET_USER_PROJECTS,
});

const getUserProjectsAction = (
  userId,
  locale: LocaleType,
  sportsFilters?: OptionSportsType[],
  page?: string,
) => {
  return (dispatch: Dispatch) => {
    return processDispatch({
      dispatch,
      request: apiProjects.getProjects({
        statusFilter: OptionsStatusValueType.user,
        sportsFilters,
        pageUrl: page,
        userId,
        sorting: true,
        locale,
      }),
      action: getUserProjects,
      response: getResponseData,
    });
  };
};

const getProject = (data: ProjectType) => ({
  payload: data,
  type: actions.GET_PROJECT,
});

const getProjectAction = (id: number, locale: LocaleType) => {
  return (dispatch: Dispatch) => {
    return processDispatch({
      dispatch,
      request: apiProjects.getProject(id, locale),
      action: getProject,
      response: getResponseData,
    });
  };
};

export const actionsProjects = {
  flushFollowedProjects,
  flushProject,
  flushProjects,
  flushUserProjects,
  getFollowedProjects,
  getFollowedProjectsAction,
  getHpFollowedProjects,
  getHpFollowedProjectsAction,
  getHpProjects,
  getHpProjectsAction,
  getProject,
  getProjectAction,
  getProjectTagsAction,
  getProjects,
  getProjectsAction,
  getTopProjects,
  getTopProjectsAction,
  getUserProjects,
  getUserProjectsAction,
  updateFollowedProjects,
  updateHpFollowedProjects,
};
