export const getCleanedAsPath = (asPath: string) => {
  const basePath = asPath?.split('?')[0];
  const search = asPath?.split('?')[1];
  if (search) {
    const params = new URLSearchParams(search);
    params.delete('code');
    params.delete('state');
    const queryparams = params.toString();
    return queryparams ? `${basePath}?${queryparams}` : basePath;
  }
  return asPath || '';
};
