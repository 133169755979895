import type { AxiosPromise, AxiosRequestConfig } from 'axios';
import {
  getRequestBuilder,
  getResponseData,
  getUnpaginatedCollection,
  processCall,
} from './common/callApi';
import { apiTimeSlots } from './apiTimeSlots';
import { shuffleArray } from '~/components/Interaction/Form/Question/utils';
import { api, apiPaths, translationsQueryParam } from '~/const/api';
import { env } from '~/const/env';
import type { InteractionType } from '~/model/InteractionType';
import { checkIsSelection } from '~/components/Interaction/utils';
import type { LocaleType } from '~/model/GlobalTypes';

const getInteraction = (
  id: number,
  locale: LocaleType,
  addTranslationGroups = true,
  getTimeSlotsIfSelection = true,
): AxiosPromise<InteractionType> => {
  const requestInteraction: AxiosRequestConfig<InteractionType>
    = getRequestBuilder(
      `${env.REACT_APP_API}${api.INTERACTIONS}/${id}?groups[]=project_media&groups[]=read_interaction_parent${
        addTranslationGroups
          ? `&${translationsQueryParam(apiPaths.INTERACTION)}`
          : ''
      }`,
      false,
      locale,
    );
  return processCall(requestInteraction, (responseInteraction) => {
    const interaction = getResponseData(responseInteraction);
    const shuffledSections = interaction.random_sort
      ? shuffleArray(interaction.sections)
      : interaction.sections;
    const shuffledInteraction = {
      ...responseInteraction,
      data: {
        ...interaction,
        sections: shuffledSections,
      },
    };

    if (
      getTimeSlotsIfSelection
      && checkIsSelection(interaction)
      && !!interaction.time_slots.length
    ) {
      return apiTimeSlots
        .getTimeSlots(id)
        .then((collection) => {
          return {
            ...shuffledInteraction,
            data: {
              ...shuffledInteraction.data,
              time_slots: collection,
            },
          };
        })
        .catch(() => shuffledInteraction);
    }
    return shuffledInteraction;
  });
};

const getUnpaginatedInteractions = async (
  locale: LocaleType,
  queryParams?: string,
) => {
  return getUnpaginatedCollection<InteractionType[]>({
    route: api.INTERACTIONS,
    queryParams: queryParams || 'state=active&order[endAt]=ASC',
    acceptLanguage: locale,
  });
};

export const checkGuestAccess = async (interactionId: number) => {
  const requestGuestAccess: AxiosRequestConfig<{ accessible: boolean }>
    = getRequestBuilder(
      `${env.REACT_APP_API}${api.INTERACTIONS}/${interactionId}/${api.GUEST_ACCESS}`,
      true,
    );
  return processCall(requestGuestAccess);
};

export const apiInteractions = {
  checkGuestAccess,
  getInteraction,
  getUnpaginatedInteractions,
};

export default apiInteractions;
