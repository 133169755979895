import { initialState } from './initialState';
import { actions } from '~/const/actions';

export const maintenance = (
  state: any = initialState.maintenance,
  action = { type: '', maintenance: null },
) => {
  if (action.type === actions.GET_MAINTENANCE)
    return action.maintenance != null ? action.maintenance : state;

  return state;
};
