import { initialState } from './initialState';
import { actions } from '~/const/actions';

export const termsOfUses = (
  state: any = initialState.termsOfUses,
  action = { type: '', payload: null },
) => {
  if (action.type === actions.GET_TERMS_OF_USES)
    return action.payload || state;

  return state;
};
