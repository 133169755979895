import { combineReducers } from '@reduxjs/toolkit';
import { authenticated, authenticationRefresh } from './authenticated';
import { errors } from './errors';
import { historyUrl } from './historyUrl';
import { interaction } from './interactions';
import { layout } from './layout';
import { sports } from './sports';
import { loading } from './loading';
import { maintenance } from './maintenance';
import {
  followedProjectsData,
  hpFollowedProjectsData,
  hpProjectsData,
  hpTopProjects,
  project,
  projectTags,
  projectsData,
  userProjectsData,
} from './projects';
import { stepTypes } from './stepTypes';
import { termsOfUses } from './termsOfUses';
import {
  children,
  user,
  userCriterias,
  userInteractionStatus,
  userLanguage,
} from './user';
import { asAdmin } from './asAdmin';

const rootReducer = combineReducers({
  authenticated,
  authenticationRefresh,
  children,
  errors,
  historyUrl,
  interaction,
  layout,
  loading,
  maintenance,
  project,
  projectsData,
  projectTags,
  hpProjectsData,
  hpFollowedProjectsData,
  hpTopProjects,
  followedProjectsData,
  userProjectsData,
  sports,
  stepTypes,
  termsOfUses,
  user,
  userInteractionStatus,
  userCriterias,
  userLanguage,
  asAdmin,
});

export default rootReducer;
