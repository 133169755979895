import type { AxiosPromise } from 'axios';
import {
  getRequestBuilder,
  getUnpaginatedCollection,
  processCall,
} from './common/callApi';
import { api } from '~/const/api';
import type { DataCollectionType } from '~/model/DataCollectionType';
import type { SelectedTimeSlotType, TimeSlotType } from '~/model/TimeSlotType';
import { env } from '~/const/env';

const getTimeslot = async (id: number) => {
  const request = getRequestBuilder<TimeSlotType>(
    `${env.REACT_APP_API}${api.TIME_SLOTS}/${id}`,
  );
  return processCall(request);
};

const getTimeSlots = (
  selectionId: number,
): AxiosPromise<DataCollectionType<TimeSlotType>> => {
  return getUnpaginatedCollection({
    route: `${api.SELECTIONS}/${selectionId}/${api.TIME_SLOTS}`,
  });
};

const getSelectedTimeSlots = async (contributionId: number) => {
  return getUnpaginatedCollection<SelectedTimeSlotType[]>({
    route: `${api.CONTRIBUTIONS}/${contributionId}/${api.SELECTED_TIME_SLOTS}`,
  });
};

export const apiTimeSlots = {
  getTimeslot,
  getSelectedTimeSlots,
  getTimeSlots,
};
