import { HYDRATE, createWrapper } from 'next-redux-wrapper';
import { AnyAction, configureStore } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import { ReduxStoreType } from './src/reducers/model';
import rootReducer from './src/reducers/rootReducer';
import { initialState } from '~/reducers/initialState';

const reducer = (state: ReduxStoreType, action: AnyAction) => {
  if (action.type === HYDRATE) {
    const { payload } = action;
    const nextState: ReduxStoreType = {
      ...state,
    };
    // If payload is equal to initialState
    // flush interaction and project
    // as it may comes from a restricted interaction fetching
    const isPayloadUnchanged = isEqual(payload, initialState);
    if (isPayloadUnchanged) {
      nextState.project = null;
      nextState.interaction = null;
    }
    if (payload.interaction !== null)
      nextState.interaction = payload.interaction;
    if (payload.project !== null) nextState.project = payload.project;
    if (payload.termsOfUses !== null)
      nextState.termsOfUses = payload.termsOfUses;
    if (payload.projectsData !== null)
      nextState.projectsData = payload.projectsData;
    if (payload.hpTopProjects !== null)
      nextState.hpTopProjects = payload.hpTopProjects;
    if (payload.hpProjectsData !== null)
      nextState.hpProjectsData = payload.hpProjectsData;
    return nextState;
  } else {
    return rootReducer(state, action);
  }
};

export const makeStore = () =>
  configureStore({
    reducer,
    devTools: process.env.NODE_ENV !== 'production',
  });

export const wrapper = createWrapper(makeStore);

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
