import { actions } from '~/const/actions';
import type { ThemeType } from '~/model/GlobalTypes';

export const toggleDarkModeAction = (theme: ThemeType) => {
  return (dispatch) => {
    dispatch({
      payload: theme,
      type: actions.TOGGLE_DARK_MODE,
    });
  };
};

export const actionsLayout = {
  toggleDarkModeAction,
};
