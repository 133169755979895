import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'next/navigation';
import { processDispatch } from '~/actions/common/dispatcher';
import { createToken } from '~/api/apiGuest';
import { checkGuestAccess } from '~/api/apiInteractions';
import { getResponseData } from '~/api/common/callApi';
import { SessionItem, entityRoutes } from '~/const/appConst';
import { actions } from '~/const/actions';
import {
  RequestStatus,
  useRequestState,
} from '~/hooks/request/useRequestState';
import type { UseDispatchType } from '~/model/GlobalTypes';
import { removeSessionStorage, setSessionStorage } from '~/utils/utils';
import type { UserType } from '~/model/UserType';
import { isGuestUser } from '~/utils/user/user';
import { userLanguageSelector } from '~/selectors/selectors';

export function useGuest(
  check = true,
  user?: UserType,
  interactionId?: number,
) {
  const dispatch = useDispatch<UseDispatchType>();

  const router = useRouter();
  const params = useParams<{ id: string }>();

  const userLanguage = useSelector(userLanguageSelector);

  const [allowed, setAllowed] = useState<boolean>(false);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const { status, startRequest, handleError, handleSuccess }
    = useRequestState();

  const surveyId = useMemo(
    () =>
      interactionId
      || ([
        `/[locale]/${entityRoutes.survey}/[id]`,
        `/[locale]/${entityRoutes.interaction}/[id]`,
      ].includes(router.pathname)
      && +params.id),
    [router, params.id, interactionId],
  );

  const init = async (id = surveyId) => {
    if (!id)
      return;
    try {
      startRequest();
      const response = getResponseData(await createToken(id, userLanguage));
      setSessionStorage(SessionItem.GUEST_TOKEN, response.token);
      handleSuccess();
    } catch (error) {
      handleError();
      throw new Error(error);
    }
  };

  const logout = () => {
    if (!isGuestUser(user))
      return;
    removeSessionStorage(SessionItem.GUEST_TOKEN);
    processDispatch({
      dispatch,
      request: Promise.resolve(),
      action: () => ({
        type: actions.DELETE_USER,
      }),
    });
  };

  const isGuestModeAllowed = useCallback(
    async (id = surveyId) => {
      if (!id)
        return false;
      try {
        startRequest();
        const { accessible } = getResponseData(await checkGuestAccess(id));
        setAllowed(accessible);
        setIsModalOpened(accessible);
        handleSuccess();
        return accessible;
      } catch (error) {
        handleError();
        throw new Error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [surveyId, check],
  );

  useEffect(() => {
    check && isGuestModeAllowed();
  }, [check, isGuestModeAllowed]);

  return {
    id: surveyId,
    isFetching: status === RequestStatus.PENDING,
    allowed,
    isGuestModeAllowed,
    isModalOpened,
    init,
    setIsModalOpened,
    logout,
  };
}
