import type { NextRouter } from 'next/router';
import { apiConnect } from '~/api/apiConnect';
import {
  getIdFromUri,
  removeSessionStorage,
  setSessionStorage,
} from '~/utils/utils';
import { CookieName, SessionItem, entityRoutes } from '~/const/appConst';
import type { UserType } from '~/model/UserType';
import { getGuestToken } from '~/api/apiGuest';
import { getResponseData } from '~/api/common/callApi';
import { getUser } from '~/api/apiUsers';
import { handleUserFavoriteLanguage } from '~/utils/user/user';

const Authentication = async (
  locale: string,
  pathname: string,
  router: NextRouter,
  initUserDispatcher: () => void,
  getUserDispatcher: () => Promise<UserType>,
  putUserDispatcher: (user: Partial<UserType>, authenticated: boolean) => void,
) => {
  // CHECK URL WHEN COMPONENT DID MOUNT
  const searchParams = new URLSearchParams(window.location.search);
  const guestToken = searchParams.get('guestToken');

  // WHILE STANDARD NAVIGATION
  if (apiConnect.getAuthenticationToken(CookieName.ACCESS_TOKEN)) {
    const refreshToken = apiConnect.getAuthenticationToken(CookieName.REFRESH_TOKEN);
    const scope = apiConnect.getRefreshTokenScope(refreshToken);
    const isExpired = apiConnect.isTokenExpired(refreshToken);
    // check if refresh token scope contains expected fields
    // and is not yet expired
    const isValidRefreshToken
      = scope
      && scope.includes('country')
      && scope.includes('email')
      && scope.includes('account:birthdate')
      && scope.includes('account:gender')
      && scope.includes('account:profile')
      && isExpired === false;

    if (isValidRefreshToken) {
      try {
        const user = await getUserDispatcher();
        handleUserFavoriteLanguage(user, locale, pathname);
      } catch (error) {
        initUserDispatcher();
        throw new Error(error);
      }
    } else {
      apiConnect.clearAccessToken();
      apiConnect.clearRefreshToken();
      initUserDispatcher();
    }
  } else if (getGuestToken() || guestToken) {
    const isExpired = apiConnect.isTokenExpired(getGuestToken() || guestToken);
    if (isExpired || router.pathname !== `/[locale]/${entityRoutes.survey}/[id]`) {
      putUserDispatcher({}, false);
      removeSessionStorage(SessionItem.GUEST_TOKEN);
    } else {
      !!guestToken && setSessionStorage(SessionItem.GUEST_TOKEN, guestToken);
      !!guestToken
      && router.query.id
      && router.push(
        { pathname, query: { id: router.query.id } },
        undefined,
        { shallow: true },
      );
      try {
        const user = getResponseData(await getUser(true));
        if (+router.query.id === getIdFromUri(user.interaction)) {
          putUserDispatcher(user, true);
          handleUserFavoriteLanguage(
            user,
            locale,
            pathname,
          );
        } else {
          putUserDispatcher({}, false);
          removeSessionStorage(SessionItem.GUEST_TOKEN);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  } else {
    initUserDispatcher();
  }
};

export default Authentication;
