import type { AxiosResponse } from 'axios';
import type { Dispatch } from '@reduxjs/toolkit';
import { getResponseDataError, initError } from '~/utils/utils';
import { throwErrorsAction } from '~/actions/actionsErrors';
import { actionsMaintenance } from '~/actions/actionsMaintenance';

interface ProcessDispatchArg {
  dispatch: Dispatch;
  request: Promise<AxiosResponse> | any;
  action?: (...args) => { type: string; payload?: any };
  response?: (arg: any) => any;
  isConnectionError?: boolean;
  isFileUpload?: boolean;
}

export const processDispatch = (
  {
    dispatch,
    request,
    action: actionCreator = null,
    response: returnResponseFunction = res => res,
    isConnectionError = false,
    isFileUpload = false,
  }: ProcessDispatchArg,
  ...rest
): Promise<any> => {
  return request
    .then((res) => {
      if (actionCreator)
        dispatch(actionCreator(returnResponseFunction(res), ...rest));
      return returnResponseFunction(res);
    })
    .catch((error) => {
      if (getResponseDataError(error, 502, 503)) {
        dispatch(actionsMaintenance.setMaintenanceMode(true));
      } else {
        dispatch(
          throwErrorsAction(
            initError({ res: error, isFileUpload, connect: isConnectionError }),
          ),
        );
      }
      return Promise.reject(error);
    });
};
