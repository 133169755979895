import { useCallback, useEffect, useState } from 'react';
import type { BreakpointsTypeKeys } from '~/const/appConst';
import {
  handleLBreakpoint,
  handleMBreakpoint,
  handleSBreakpoint,
  handleXLBreakpoint,
  matchMediaQuery,
} from '~/utils/utils';

export function useBreakpoint() {
  const [breakpoint, setBreakpoint] = useState<BreakpointsTypeKeys>();

  const isSBreakpoint = handleSBreakpoint(breakpoint);
  const isMBreakpoint = handleMBreakpoint(breakpoint);
  const isLBreakpoint = handleLBreakpoint(breakpoint);
  const isXLBreakpoint = handleXLBreakpoint(breakpoint);

  const isMBreakpointMin = isMBreakpoint || isLBreakpoint || isXLBreakpoint;
  const isLBreakpointMin = isLBreakpoint || isXLBreakpoint;

  const onMatchMedia = useCallback(() => {
    matchMediaQuery(setBreakpoint);
  }, []);

  useEffect(() => {
    matchMediaQuery(setBreakpoint);
    window.addEventListener('resize', onMatchMedia);

    return () => window.removeEventListener('resize', onMatchMedia);
  }, []);

  return {
    isSBreakpoint,
    isMBreakpoint,
    isLBreakpoint,
    isXLBreakpoint,
    isMBreakpointMin,
    isLBreakpointMin,
  };
}
