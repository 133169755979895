import router from 'next/router';
import type { FunctionComponent } from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from '../Loader/Loader';
import type { ReduxStoreType } from '~/reducers/model';

export const PageLoader: FunctionComponent = () => {
  const [display, setdisplay] = useState(false);
  const setDisplayTrue = () => setdisplay(true);
  const setDisplayFalse = () => setdisplay(false);
  const loading = useSelector((state: ReduxStoreType) => state.loading);

  useEffect(() => {
    router.events.on('routeChangeStart', setDisplayTrue);
    router.events.on('routeChangeComplete', setDisplayFalse);
    router.events.on('routeChangeError', setDisplayFalse);
    return () => {
      router.events.off('routeChangeStart', setDisplayTrue);
      router.events.off('routeChangeComplete', setDisplayFalse);
      router.events.off('routeChangeError', setDisplayFalse);
    };
  }, []);

  return (
    (display || loading) && (
      <Loader loading overlay size="large" />
    )
  );
};
