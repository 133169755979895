import { initialState } from './initialState';
import { actions } from '~/const/actions';

export const loading = (
  state: boolean = initialState.loading,
  action = { type: '', payload: null },
) => {
  if (action.type === actions.SET_LOADING)
    return action.payload;

  return state;
};
