import { useState } from 'react';

export enum RequestStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  ERROR = 'error',
  SUCCESS = 'success',
}

export const useRequestState = () => {
  const [status, setStatus] = useState<RequestStatus>(RequestStatus.IDLE);

  const startRequest = () => {
    setStatus(RequestStatus.PENDING);
  };

  const handleSuccess = () => {
    setStatus(RequestStatus.SUCCESS);
  };

  const handleError = () => {
    setStatus(RequestStatus.ERROR);
  };

  return {
    status,
    startRequest,
    handleSuccess,
    handleError,
  };
};
