import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale, useTranslations } from 'next-intl';
import classNames from 'classnames';
import type { VpAssetNameType } from '@vtmn-play/react/headless';
import { VpAsset, VpIcon } from '@vtmn-play/react/headless';
import { useSearchParams } from 'next/navigation';
import { PopperComponent } from '../commons/Popper/PopperComponent';
import { newUrlFromLocaleChange } from './utils';
import appConst, { CookieName } from '~/const/appConst';
import { Loader } from '~/components/commons/Loader/Loader';
import {
  getLanguageFromLocale,
  getLocaleFromLanguage,
  setCookie,
} from '~/utils/utils';
import { actionsUser } from '~/actions/actionsUser';
import type { LocaleType, UseDispatchType } from '~/model/GlobalTypes';
import { isAuthenticated, isGuestUser } from '~/utils/user/user';
import { authenticatedSelector, userSelector } from '~/selectors/selectors';
import type { LocaleCode } from '~/const/locale';
import { acceptLanguages, defaultLocale, usePathname } from '@/navigation';

export const LanguageSwitcher = () => {
  const t = useTranslations();
  const locale = useLocale();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const authenticated = useSelector(authenticatedSelector);
  const user = useSelector(userSelector);
  const dispatch = useDispatch<UseDispatchType>();

  const [loading, setLoading] = useState(false);
  const [isPopperOpened, setIsPopperOpened] = useState(false);
  const [currentLocale, setCurrentLocale] = useState<LocaleType>(() =>
    getLocaleFromLanguage((locale || defaultLocale) as LocaleCode, true),
  );

  const activeLanguage = user?.favorite_locale || currentLocale;

  const updateUserLocale = async (newUserLocale: string) => {
    try {
      setLoading(true);
      await dispatch(
        actionsUser.putUserAction(
          { favorite_locale: newUserLocale },
          activeLanguage,
          user.sports,
          isGuestUser(user) ? user.id : null,
        ),
      );
    } catch (error) {
      setLoading(false);
    }
  };

  const handleChangeLocale = async (newLocale: LocaleType) => {
    // Store the chosen locale in a dedicated cookie
    // Update locale state accordingly
    setCurrentLocale(newLocale);
    // Update the user's favourite locale if different
    try {
      isAuthenticated(user, authenticated)
      && (await updateUserLocale(newLocale));

      setCookie(`${CookieName.COCREATION_LOCALE}=${newLocale}`);
      setCookie(`${CookieName.NEXT_LOCALE}=${getLanguageFromLocale(newLocale)}`);

      const search = searchParams.toString() ? `?${searchParams.toString()}` : '';

      if (!pathname.includes('/_error')) {
        window.location.replace(newUrlFromLocaleChange(
          newLocale,
          `${pathname}${search}`,
        ));
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    dispatch(actionsUser.setUserLanguage(currentLocale));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PopperComponent
      ariaLabel={`${t('NAVIGATION.LANGUAGE')} : ${activeLanguage}`}
      arrow
      isOpen={isPopperOpened}
      placement="top"
      setIsOpen={setIsPopperOpened}
      text={(
        <>
          <VpAsset
            name={
              `flag-${(user?.favorite_locale || currentLocale).split('_')[1].toLowerCase()}` as VpAssetNameType
            }
            width={12}
          />
          {' '}
          {appConst.labelLocales[activeLanguage]}
          <VpIcon name={`chevron-${isPopperOpened ? 'up' : 'down'}`} />
        </>
      )}
    >
      <Loader loading={loading}>
        <ul tabIndex={-1}>
          {acceptLanguages.map((locale, index) => {
            return (
              <li key={locale} data-testid="language-item">
                <button
                  type="button"
                  id={locale}
                  className={classNames('popper__option', {
                    'popper__option--active':
                      locale === (user?.favorite_locale || currentLocale),
                  })}
                  {...(index === 0 && { autoFocus: true })}
                  onClick={() => handleChangeLocale(locale)}
                >
                  <VpAsset
                    name={
                      `flag-${locale.split('_')[1].toLowerCase()}` as VpAssetNameType
                    }
                    width={20}
                  />
                  {' '}
                  {appConst.labelLocales[locale]}
                </button>
              </li>
            );
          })}
        </ul>
      </Loader>
    </PopperComponent>
  );
};
