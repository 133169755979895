import Script from 'next/script';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userLanguageSelector, userSelector } from '~/selectors/selectors';
import { convertLocaleSeparator } from '~/utils/utils';

export interface MedalliaCustomParams {
  languageCode?: string;
  jobName?: string;
  uid?: string;
  env?: string;
}

export const Medallia = () => {
  const user = useSelector(userSelector);
  const language = useSelector(userLanguageSelector);
  const [medalliaConsentStatus, setMedalliaConsentStatus] = useState(false);

  useEffect(() => {
    window.didomiOnReady = window.didomiOnReady || [];
    window.didomiOnReady.push((Didomi) => {
      if (Didomi.isConsentRequired()) {
        Didomi.getObservableOnUserConsentStatusForVendor(
          process.env.MEDALLIA_VENDOR_ID,
        ).subscribe((consentStatusForVendor) => {
          setMedalliaConsentStatus(consentStatusForVendor);
        });
      }
    });
  }, []);

  useEffect(() => {
    if (medalliaConsentStatus) {
      const userLanguage = convertLocaleSeparator({ locale: user?.favorite_locale || language });

      window.medalliaCustomParams = {
        languageCode: userLanguage,
        ...user?.id && {
          jobName: user.job,
          uid: `${user.id}`,
        },
        env: process.env.ENVIRONMENT,
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, language, medalliaConsentStatus]);

  return (
    medalliaConsentStatus
      ? (
        <Script
          id="medallia"
          type="text/javascript"
          strategy="lazyOnload"
          src={`https://resources.digital-cloud-west.medallia.com/wdcwest/${process.env.MEDALLIA_PROPERTY_ID}/onsite/embed.js`}
        />
        )
      : null
  );
};
