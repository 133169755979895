import type { Dispatch } from 'react';
import type { MessageKeys } from 'next-intl';
import { getMarks } from './Form/Question/Types/Scale';
import { statusContribution } from '~/const/api';
import type { HistoryType } from '~/model/HistoryType';
import {
  ComponentTranslationStatus,
  ElementType,
} from '~/const/appConst';
import type {
  InteractionType,
  UserInteractionStatusType,
} from '~/model/InteractionType';
import {
  InteractionTypes,
} from '~/model/InteractionType';
import type { UserContribStatusCountType } from '~/model/UserContribStatusCountType';
import type { ConclusionType } from '~/model/ConclusionType';
import type { LocaleType } from '~/model/GlobalTypes';
import type { TranslationType } from '~/model/TranslationType';
import type { Messages } from '@/global';
import { acceptLanguages } from '@/navigation';

export const checkIsSelection = (interaction: InteractionType) => {
  return interaction?.['@type'] === InteractionTypes.selection;
};

export const checkIsSurvey = (interaction: InteractionType) => {
  return interaction?.['@type'] === InteractionTypes.survey;
};

export const checkIsThread = (interaction: InteractionType) => {
  return interaction?.['@type'] === InteractionTypes.thread;
};

export const checkIsAnnouncement = (interaction: InteractionType) => {
  return interaction?.['@type'] === InteractionTypes.announcement;
};

export const hasContribution = (statuses: UserContribStatusCountType) => {
  return statuses && Object.values(statuses).some(s => s > 0);
};

export const checkIfNoPlacesLeft = (interaction: InteractionType) =>
  interaction?.time_slots?.length > 0
  && interaction.time_slots.every(
    t =>
      t.number_of_place !== null
      && t.number_of_place > 0
      && t.selected_time_slots_accepted_count
      + t.selected_time_slots_confirmed_count
      >= t.number_of_place,
  );

export const getNthLastRouteFromHistory = (
  historyUrl: HistoryType[],
  fromEnd = 1,
) => {
  return historyUrl && historyUrl.length > 0
    ? historyUrl[historyUrl.length - fromEnd]
    : undefined;
};

export const getStatusLabel = (
  TimeslotStatus: string,
): MessageKeys<
  Messages,
  | 'INTERACTION.CONTRIBUTION_STATUS.SUBMITTED'
  | 'SELECTION.STATUS_CONTRIB_ACCEPTED'
  | 'SELECTION.STATUS_CONTRIB_CONFIRMED'
  | 'INTERACTION.CONTRIBUTION_STATUS.UNCONFIRMED'
  | 'SELECTION.STATUS_CONTRIB_REFUSED'
> => {
  switch (TimeslotStatus) {
    case statusContribution.submitted:
      return 'INTERACTION.CONTRIBUTION_STATUS.SUBMITTED';
    case statusContribution.accepted:
      return 'SELECTION.STATUS_CONTRIB_ACCEPTED';
    case statusContribution.confirmed:
      return 'SELECTION.STATUS_CONTRIB_CONFIRMED';
    case statusContribution.unconfirmed:
      return 'INTERACTION.CONTRIBUTION_STATUS.UNCONFIRMED';
    case statusContribution.refused:
      return 'SELECTION.STATUS_CONTRIB_REFUSED';
    default:
      return '' as any;
  }
};

export const isNotEmptyString = (field: string) => {
  if (field !== '' && field !== null)
    return true;

  return false;
};

export const calculateNumberOfMarks = (
  scaleSliderCanBeRendered: boolean,
  step: number,
  limit_min: number,
  limit_max: number,
) => {
  return scaleSliderCanBeRendered
    && step !== 0
    && step !== undefined
    && limit_min !== undefined
    && limit_max !== undefined
    ? (limit_max - limit_min) / step
    : 0;
};

export const calculateMarks = (
  scaleSliderCanBeRendered: boolean,
  numberOfMarks: number,
  step: number,
  limit_min: number,
) => {
  return scaleSliderCanBeRendered && numberOfMarks > 0 && step !== undefined
    ? getMarks(numberOfMarks, step, limit_min)
    : {};
};

export const calculateSliderMarks = (
  scaleSliderCanBeRendered: boolean,
  marks: any,
  limit_min: number,
  limit_max: number,
) => {
  return scaleSliderCanBeRendered
    && limit_min !== undefined
    && limit_max !== undefined
    ? marks
    : { // Use min and max as only marks
        [limit_min]: limit_min,
        [limit_max]: limit_max,
      };
};

export interface UpdateConclusionToDisplayUtilsPropsType {
  conclusions: ConclusionType[];
  conditionalConclusion: ConclusionType;
  getConclusionDispatcher: (
    conclusionId: number,
    locale?: string,
  ) => Promise<ConclusionType>;
  languageToDisplay: LocaleType;
  defaultConclusion: ConclusionType;
  setConclusion: Dispatch<ConclusionType>;
}

export const updateConclusionToDisplayUtils = ({
  conclusions,
  conditionalConclusion,
  getConclusionDispatcher,
  languageToDisplay,
  defaultConclusion,
  setConclusion,
}: UpdateConclusionToDisplayUtilsPropsType) => {
  if (conclusions?.length > 0) {
    const mainConclusion = conclusions.find(c => !c.branch_choice);
    const hasConditionalConclusion
      = conditionalConclusion && conditionalConclusion.id !== 0;
    const hasConclusion = !!mainConclusion || !!hasConditionalConclusion;
    if (hasConclusion) {
      getConclusionDispatcher(
        hasConditionalConclusion ? conditionalConclusion.id : mainConclusion.id,
        languageToDisplay,
      ).then((conclusion) => {
        setConclusion(conclusion);
      });
    } else {
      setConclusion(defaultConclusion);
    }
  } else {
    setConclusion(defaultConclusion);
  }
};

export const buildDefaultConclusion = (body: string): ConclusionType => {
  return {
    '@type': ElementType.CONCLUSION,
    body,
    id: 0,
    title: '',
    translations: acceptLanguages.reduce((acc: TranslationType, current) => {
      acc[current] = {
        locale: current,
        status: ComponentTranslationStatus.valid,
        translation_engine: null,
      };
      return acc;
    }, {} as TranslationType),
  };
};

export const checkUserCriteriaStatus = (
  userInteractionStatus: UserInteractionStatusType,
) => {
  return {
    mismatchedCriterias:
      userInteractionStatus?.current_user_mismatched_target_criteria || [],
    outdatedCriterias:
      userInteractionStatus?.current_user_outdated_target_criteria || [],
    missingCriterias:
      userInteractionStatus?.current_user_missing_target_criteria || [],
  };
};
