import update from 'immutability-helper';
import { initialState } from './initialState';
import type { CriteriaChoiceUserToDelete } from '~/components/Profile/model';
import { actions } from '~/const/actions';
import type { CriteriaChoiceUserType } from '~/model/CriteriaChoiceType';
import type { UserType } from '~/model/UserType';
import { filterUserCriteria } from '~/utils/user-criteria/filter';

const buildUserCriteriaState = (state, payload) => {
  return state ? filterUserCriteria(state, payload) : payload;
};

export const user = (
  state: UserType | Record<string, any> = initialState.user,
  action: {
    type: string;
    user?: UserType;
    keyAttribute?: string;
    childId?: number;
    data?: {
      [key: string]: any;
    };
  } = { type: '' },
) => {
  switch (action.type) {
    case actions.GET_USER:
      return action.user || state;

    case actions.PUT_USER:
      return action.user ? action.user : state;

    // (childId, data, keyAttribute)
    case actions.PUT_USER_DATA: {
      // ignore if the action comes from a child PUT action
      if (action.childId)
        return state;
      const keyAttribute = action.keyAttribute;
      const modifiedUserData = action.data[keyAttribute];
      return update(state, {
        [keyAttribute]: { $set: modifiedUserData },
      });
    }

    // re-initialize user
    case actions.DELETE_USER:
      return {};

    default:
      return state;
  }
};

export const userInteractionStatus = (
  state = initialState.userInteractionStatus,
  action = { type: '', payload: null },
) => {
  switch (action.type) {
    case actions.GET_USER_INTERACTION_STATUS: {
      return { ...state, ...action.payload };
    }
    case actions.FLUSH_USER_INTERACTION_STATUS: {
      return null;
    }
    case actions.POST_SURVEY_ANSWERS: {
      return update(state, {
        current_user_contributor: { $set: true },
      });
    }
    case actions.POST_INTERACTION_REACTION: {
      return update(state, {
        current_user_reaction: { $set: action.payload },
      });
    }
    case actions.DELETE_INTERACTION_REACTION: {
      return update(state, {
        current_user_reaction: { $set: null },
      });
    }
    default:
      return state;
  }
};

export const userCriterias = (
  state: CriteriaChoiceUserType[] = initialState.userCriterias,
  action: {
    type: string;
    payload?: Array<CriteriaChoiceUserType & CriteriaChoiceUserToDelete>;
    criteria?: CriteriaChoiceUserType;
    childId?: number;
    criteriaId?: number;
  } = { type: '', payload: [] },
) => {
  switch (action.type) {
    case actions.GET_USER_CRITERIAS:
      return action.payload;

    case actions.FLUSH_USER_CRITERIAS:
      return null;

    case actions.POST_USER_CRITERIA:
      return state ? [...state, action.criteria] : [action.criteria];

    case actions.POST_USER_CRITERIAS: {
      // ignore if the action comes from a child POST CRITERIAS action
      if (action.childId)
        return state;

      return state ? [...state, ...action.payload] : action.payload;
    }

    case actions.UPDATE_USER_CRITERIAS: {
      // ignore if the action comes from a child UPDATE CRITERIAS action
      if (action.childId)
        return state;
      return buildUserCriteriaState(state, action.payload);
    }

    case actions.DELETE_USER_CRITERIA: {
      // if the action concerns a child
      if (action.childId)
        return state;

      const prevState = state ? [...state] : [];
      const criteriaToDeleteId = action.criteriaId;
      const criteriaToDeleteIndex
        = prevState.length > 0
        && prevState.findIndex(c => c.id === criteriaToDeleteId);
      prevState.splice(criteriaToDeleteIndex, 1);
      return prevState;
    }

    default:
      return state;
  }
};

export const children = (
  state: UserType[] = initialState.children,
  action: {
    type: string;
    children?: UserType[];
    child?: UserType;
    childId?: number;
    keyAttribute?: string;
    criteria?: CriteriaChoiceUserType;
    criteriaId?: number;
    data?: Record<string, any>;
    payload?: Array<CriteriaChoiceUserType & CriteriaChoiceUserToDelete>;
  } = { type: '' },
) => {
  switch (action.type) {
    case actions.GET_CHILDREN:
      return action.children ? action.children : state;

    // Use to update a child's criteria answers
    case actions.GET_CHILD: {
      const prevStateGetChild = [...state];
      // Get the modified child
      const updatedChild = action.child;
      // Get the array index of the modified child
      const updatedChildIndex = prevStateGetChild
        .map(child => child.id)
        .indexOf(action.child.id);
      // Update only the modified children
      return update(state, {
        [updatedChildIndex]: { $set: updatedChild },
      });
    }

    case actions.POST_CHILD:
      return action.children;

    case actions.PUT_CHILD: {
      const prevStatePutChildren = [...state];
      // Get the modified child
      const updatedChild = action.child;
      // Get the array index of the modified child
      const indexChild = prevStatePutChildren
        .map(child => child.id)
        .indexOf(action.child.id);
      // Keep sports and criterion_choice_users if already exist
      const existingSports = state[indexChild].sports;
      const existingCriterias = state[indexChild].criterion_choice_users;
      const mergedChild: UserType = {
        ...updatedChild,
        ...(existingSports ? { sports: existingSports } : {}),
        ...(existingCriterias
          ? { criterion_choice_users: existingCriterias }
          : {}),
      };
      // Update only the modified children
      return update(state, {
        [indexChild]: { $set: mergedChild },
      });
    }

    // (childId, data, keyAttribute)
    case actions.PUT_USER_DATA: {
      // ignore if the action comes from user PUT action
      if (!action.childId)
        return state;

      const prevStatePutDataChildren = [...state];
      const keyAttribute = action.keyAttribute;
      // Get the array index of the modified child
      const indexPutDataChild = prevStatePutDataChildren
        .map(child => child.id)
        .indexOf(action.childId);
      const modifiedUserData = action.data[keyAttribute];
      return update(state, {
        [indexPutDataChild]: {
          [keyAttribute]: { $set: modifiedUserData },
        },
      });
    }

    case actions.DELETE_CHILD:
      return action.children;

    case actions.UPDATE_USER_CRITERIAS: {
      // ignore if the action comes from the user UPDATE CRITERIAS action
      if (!action.childId)
        return state;

      const prevStateChildren = [...state];

      const childIndex = prevStateChildren
        .map(child => child.id)
        .indexOf(action.childId);

      const prevStateUserCriterias = state[childIndex].criterion_choice_users;

      const userCriteriasList = buildUserCriteriaState(
        prevStateUserCriterias,
        action.payload,
      );

      const updatedChild = {
        ...state[childIndex],
        criterion_choice_users: userCriteriasList,
      };

      return update(state, {
        [childIndex]: {
          $set: updatedChild,
        },
      });
    }

    case actions.POST_USER_CRITERIAS: {
      // ignore if the action comes from the user POST CRITERIAS action
      if (!action.childId)
        return state;

      // Get the new criterias
      const newCriterias = action.payload;
      // Get current Child
      const postChildId = action.childId;
      // Get the array index of the modified child
      const postChildIndex = state.map(child => child.id).indexOf(postChildId);

      const prevStatePostCriteriasAnswers
        = state[postChildIndex].criterion_choice_users;
      // Create or update the criterias list
      const newCriteriasList = prevStatePostCriteriasAnswers
        ? [...prevStatePostCriteriasAnswers, ...newCriterias]
        : newCriterias;
      const newChild = prevStatePostCriteriasAnswers
        ? {
            ...state[postChildIndex],
            criterion_choice_users: newCriteriasList,
          }
        : { ...state[postChildIndex] };

      // don't update child criterias in the store if they were not loaded before
      // they will be fetched on the child tab profile page
      return prevStatePostCriteriasAnswers
        ? update(state, {
          [postChildIndex]: {
            $set: newChild,
          },
        })
        : state;
    }

    case actions.POST_CHILD_CRITERIA: {
      const prevStatePostCriteriaChildren = [...state];
      // Get the array index of the modified child
      const postCriteriaChildIndex = prevStatePostCriteriaChildren
        .map(child => child.id)
        .indexOf(action.childId);
      return update(state, {
        [postCriteriaChildIndex]: {
          criterion_choice_users: {
            $push: [action.criteria],
          },
        },
      });
    }

    case actions.DELETE_USER_CRITERIA: {
      // ignore if the action concerns the user
      if (!action.childId)
        return state;

      // Get criteria to delete id
      const criteriaToDeleteId = action.criteriaId;
      // Get current Child
      const deleteCriteriaChildId = action.childId;

      // Get the array index of the modified child
      const deleteCriteriaChildIndex = state
        .map(child => child.id)
        .indexOf(deleteCriteriaChildId);
      const prevStateDeleteCriteriaAnswers = [
        ...state[deleteCriteriaChildIndex].criterion_choice_users,
      ];
      const criteriaToDeleteIndex
        = prevStateDeleteCriteriaAnswers
        && prevStateDeleteCriteriaAnswers.length > 0
        && prevStateDeleteCriteriaAnswers.findIndex(
          criteria => criteria.id === criteriaToDeleteId,
        );
      // delete criteria from list
      prevStateDeleteCriteriaAnswers.splice(criteriaToDeleteIndex, 1);

      // Create or update the children list and criterias
      return update(state, {
        [deleteCriteriaChildIndex]: {
          criterion_choice_users: {
            $set: prevStateDeleteCriteriaAnswers,
          },
        },
      });
    }

    default:
      return state;
  }
};

export const userLanguage = (
  state: any = initialState.userLanguage,
  action = { type: '', payload: null },
) => {
  if (action.type === actions.SET_LOCALE)
    return action.payload;

  return state;
};
