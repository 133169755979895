import type { ChoiceType } from '~/model/ChoiceType';

export const subQuestionCanBeSubmit = (
  subQuestions,
  answerUser,
  questionId,
) => {
  let isQuestionAnswered = false;
  const answeredQuestions = answerUser;
  const isSubQuestionAnswered = [];
  if (subQuestions && subQuestions.length > 0) {
    subQuestions.forEach((subQuestion, i) => {
      const subQuestionId = subQuestion['@id'];
      const subQuestionAnswersKeys
        = answeredQuestions
        && answeredQuestions[questionId]
        && Object.keys(answeredQuestions[questionId].answer_choices);

      // check if the subquestion uri is present in the state
      isSubQuestionAnswered[i]
        = subQuestionAnswersKeys
        && subQuestionAnswersKeys.includes(subQuestionId);
    });
    isQuestionAnswered = isSubQuestionAnswered.every(
      validity => validity === true,
    );
  }
  return isQuestionAnswered;
};

export const itemsLetters = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

export const shuffleArray = (array) => {
  const tmpArray = [...array];
  let currentIndex = tmpArray.length;
  let temporaryValue;
  let randomIndex;
  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    // And swap it with the current element.
    temporaryValue = tmpArray[currentIndex];
    tmpArray[currentIndex] = tmpArray[randomIndex];
    tmpArray[randomIndex] = temporaryValue;
  }
  return tmpArray;
};

// Check if at least one choice has a media
export const checkIfHasPictureChoice = (choices: ChoiceType[]) =>
  choices?.reduce((acc, cur) => (cur.media ? true : acc), false);

export const getPicturesList = (answerChoices: ChoiceType[]) =>
  (answerChoices?.length > 0
  && answerChoices
    .filter(choice => choice.media)
    .map(choice => choice.media.content_url))
    || null;
