import type { CriteriaChoiceUserToDelete } from '~/components/Profile/model';
import type { CriteriaChoiceUserType } from '~/model/CriteriaChoiceType';

export const filterUserCriteria = (
  state: CriteriaChoiceUserType[],
  data: Array<CriteriaChoiceUserType & CriteriaChoiceUserToDelete>,
) => {
  return data.reduce(
    (acc, cur) => {
      // Deletion
      if (cur.toDelete) {
        // find item to delete index
        const toDeleteIndex = acc.findIndex(
          c => c.criterion['@id'] === cur['@id'],
        );
        // and remove it
        acc.splice(toDeleteIndex, 1);
      } else {
        // if a matching item is found
        const toUpdateIndex = acc.findIndex(uc => uc['@id'] === cur['@id']);
        if (toUpdateIndex > -1) {
          // update it
          acc[toUpdateIndex] = cur;
        } else {
          // or add it to the list
          acc.push(cur);
        }
      }
      return acc;
    },
    [...state],
  );
};
