import type { ReduxStoreType } from '~/reducers/model';

export const userSelector = (store: ReduxStoreType) => store.user;

export const historyUrlSelector = (store: ReduxStoreType) => store.historyUrl;

export const authenticatedSelector = (store: ReduxStoreType) =>
  store.authenticated;

export const childrenSelector = (store: ReduxStoreType) => store.children;

export const userLanguageSelector = (store: ReduxStoreType) =>
  store.userLanguage;

export const interactionSelector = (store: ReduxStoreType) => store.interaction;

export const userInteractionStatusSelector = (store: ReduxStoreType) =>
  store.userInteractionStatus;

export const userCriteriasSelector = (store: ReduxStoreType) =>
  store.userCriterias;

export const sportsSelector = (store: ReduxStoreType) => store.sports;

export const projectSelector = (store: ReduxStoreType) => store.project;

export const projectsDataSelector = (store: ReduxStoreType) =>
  store.projectsData;

export const hpProjectsDataSelector = (store: ReduxStoreType) =>
  store.hpProjectsData;

export const hpFollowedProjectsSelector = (store: ReduxStoreType) =>
  store.hpFollowedProjectsData;

export const followedProjectsSelector = (store: ReduxStoreType) =>
  store.followedProjectsData;

export const hpTopProjectsSelector = (store: ReduxStoreType) =>
  store.hpTopProjects;

export const userProjectsDataSelector = (store: ReduxStoreType) =>
  store.userProjectsData;

export const projectTagsSelector = (store: ReduxStoreType) => store.projectTags;

export const errorsSelector = (store: ReduxStoreType) => store.errors;

export const maintenanceSelector = (store: ReduxStoreType) => store.maintenance;
