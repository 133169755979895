import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePathname } from '@/navigation';
import { actionsHistoryUrl } from '~/actions/actionsHistoryUrl';
import { actionsMaintenance } from '~/actions/actionsMaintenance';
import { getCleanedAsPath } from '~/components/Authentication/utils';
import { env } from '~/const/env';
import type { UseDispatchType } from '~/model/GlobalTypes';
import { historyUrlSelector } from '~/selectors/selectors';
import { getRouteFallback } from '~/utils/utils';

export function useHistory() {
  const dispatch = useDispatch<UseDispatchType>();
  const router = useRouter();
  const pathname = usePathname();

  const historyUrl = useSelector(historyUrlSelector);

  const addHistoryUrlDispatcher = (href: string, asPath: string) =>
    dispatch(actionsHistoryUrl.addHistoryUrl(href, asPath));

  useEffect(() => {
    dispatch(actionsMaintenance.setMaintenanceMode(env.MAINTENANCE_MODE === 1));
    // History management
    const href = router.pathname;
    const asPath = pathname;
    addHistoryUrlDispatcher(
      getRouteFallback(href),
      getRouteFallback(getCleanedAsPath(asPath)),
    );
  }, []);

  useEffect(() => {
    // History management
    const href = router.pathname;
    if (
      historyUrl.length
      && historyUrl[historyUrl.length - 1].href !== href
      && !pathname.includes('code=')
    ) {
      addHistoryUrlDispatcher(
        getRouteFallback(href),
        getRouteFallback(getCleanedAsPath(pathname)),
      );
    }
  }, [router.pathname]);
}
