import type { ReduxStoreType } from './model';
import { ThemeType } from '~/model/GlobalTypes';

export const initialState: ReduxStoreType = {
  // LAYOUT
  layout: {
    theme: ThemeType.system,
  },

  // SPORTS
  sports: null,

  // STEP_TYPES
  stepTypes: null,

  // USER
  authenticated: null,
  authenticationRefresh: null,
  user: null,
  userCriterias: null,
  userInteractionStatus: null,
  userLanguage: null,

  // CHILDREN
  children: null,

  // MISC
  errors: {},
  historyUrl: [],
  loading: false,
  maintenance: null,

  // Terms Of Uses
  termsOfUses: null,

  // INTERACTIONS
  interaction: null,
  project: null,
  projectsData: null,
  projectTags: null,
  hpProjectsData: null,
  followedProjectsData: null,
  userProjectsData: null,
  hpFollowedProjectsData: null,
  hpTopProjects: null,

  // Comments
  asAdmin: false,
};
