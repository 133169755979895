import { processDispatch } from './common/dispatcher';
import { apiConnect } from '~/api/apiConnect';
import { actions } from '~/const/actions';
import { getResponseDataError } from '~/utils/utils';

export const getAuthenticated = authenticated => ({
  authenticated,
  type: actions.GET_AUTHENTICATED,
});

export const getAuthenticationRefresh = (authenticationRefresh: Date) => ({
  authenticationRefresh: JSON.stringify(authenticationRefresh),
  type: actions.GET_AUTHENTICATION_REFRESH,
});

// AUTHENTICATION DKT CONNECT VIA THE API
// Param `isLoggingOut` can be used to force authentication to false and logout
// (clear local storage)
export const getAuthenticatedAction = (postData, isLoggingOut?: boolean) => {
  return (dispatch) => {
    return processDispatch({
      dispatch,
      request: apiConnect.getUserToken(postData, isLoggingOut),
      action: getAuthenticated,
      response: res => (!(!res || getResponseDataError(res))),
      isConnectionError: true,
    }).then((res) => {
      const token = res && res.access_token;
      const expirationDate = apiConnect.getTokenExpirationDate(token);

      if (expirationDate)
        dispatch(getAuthenticationRefresh(expirationDate));
    });
  };
};

// REFRESH DKT CONNECT TOKEN
export const refreshAuthenticatedAction = () => {
  return (dispatch) => {
    return processDispatch({
      dispatch,
      request: apiConnect.refreshUserToken(),
      action: getAuthenticated,
      response: res => (!(!res || getResponseDataError(res))),
      isConnectionError: true,
    }).then((res) => {
      const token = res && res.access_token;
      if (token) {
        // update authenticationRefresh with current date
        dispatch(getAuthenticationRefresh(new Date()));
      }
    });
  };
};

export const actionsAccount = {
  getAuthenticatedAction,
  refreshAuthenticatedAction,
};
