import { useEffect, useReducer, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import type { MessageKeys } from 'next-intl';
import { useTranslations } from 'next-intl';
import type { VpIconNameType } from '@vtmn-play/react/headless';
import { VpIcon } from '@vtmn-play/react/headless';
import { PopperComponent } from '../commons/Popper/PopperComponent';
import { actionsLayout } from '~/actions/actionsLayout';
import type { UseDispatchType } from '~/model/GlobalTypes';
import { ThemeType } from '~/model/GlobalTypes';
import type { ReduxStoreType } from '~/reducers/model';
import {
  getCookie,
  getLocalStorage,
  getUserPreferedColorScheme,
  isPreferedColorSchemeSupported,
  setCookie,
  setLocalStorage,
} from '~/utils/utils';
import layout from '~/const/layout';
import type { Messages } from '@/global';

const getIconFromTheme = (value: ThemeType): VpIconNameType => {
  switch (value) {
    case ThemeType.light:
      return 'sun' as VpIconNameType;
    case ThemeType.dark:
      return 'moon' as VpIconNameType;
    default:
      return 'mac' as VpIconNameType;
  }
};

const getCurrentIcon = ({ isSystemTheme, userPreferedColorScheme, theme }) =>
  isSystemTheme
    ? getIconFromTheme(userPreferedColorScheme || ThemeType.light)
    : getIconFromTheme(theme);

export const ThemeSwitcher = () => {
  const t = useTranslations();

  const dispatch = useDispatch<UseDispatchType>();
  const theme = useSelector((state: ReduxStoreType) => state.layout.theme);

  const [_, forceUpdate] = useReducer(x => x + 1, 0);
  const [isPopperOpened, setIsPopperOpened] = useState(false);

  const initialTheme = getCookie('theme') || getLocalStorage('theme') || ThemeType.system;

  const isSystemTheme = theme === ThemeType.system;
  const isThemeDefined = !isSystemTheme;
  const userPreferedColorScheme = getUserPreferedColorScheme();

  const handleSwitch = (value: ThemeType) => {
    dispatch(actionsLayout.toggleDarkModeAction(value));
    setLocalStorage('theme', value);
    setCookie(`theme=${value}`);
    setIsPopperOpened(false);
  };

  const themeClassSuffix = isThemeDefined ? theme : userPreferedColorScheme;
  const themeClassBase = 'vtmn-new-visual-identity vp--wonder-theme';
  const themeClass = themeClassSuffix
    ? `${themeClassBase} vp--${themeClassSuffix}-mode vtmn-new-visual-identity--${themeClassSuffix}`
    : themeClassBase;

  useEffect(() => {
    if (initialTheme)
      dispatch(actionsLayout.toggleDarkModeAction(initialTheme));
    // If user changes his prefered color scheme
    // and system mode is selected,
    // force a rerender to compute user preference's new value
    const mql = window.matchMedia(layout.matchMedia.dark);
    const handleChangeColorScheme = () => {
      const updatedPreference = getUserPreferedColorScheme();
      if (isSystemTheme && updatedPreference)
        forceUpdate();
    };
    mql.addEventListener('change', handleChangeColorScheme);
    // Remove event listener on unmount
    return () => {
      mql.removeEventListener('change', handleChangeColorScheme);
    };
  }, []);

  useEffect(() => {
    document.querySelector('body').className = themeClass;
  }, [themeClass]);

  const currentIcon = getCurrentIcon({
    isSystemTheme,
    userPreferedColorScheme,
    theme,
  });

  const currentThemeLabel = t(
    `GLOBAL.THEME.${theme.toUpperCase()}` as MessageKeys<
      Messages,
      'GLOBAL.THEME.DARK' | 'GLOBAL.THEME.LIGHT' | 'GLOBAL.THEME.SYSTEM'
    >,
  );
  const openerLabel = `${t('GLOBAL.THEME.CHANGE')} : ${currentThemeLabel}`;

  // Mount the component only client-side (via useEffect)
  // to avoid mismatched content between server and client
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    mounted && (
      <PopperComponent
        ariaLabel={openerLabel}
        arrow
        isOpen={isPopperOpened}
        placement="top"
        setIsOpen={setIsPopperOpened}
        text={(
          <>
            <VpIcon name={currentIcon} size={16} />
            {openerLabel}
            <VpIcon
              name={`chevron-${isPopperOpened ? 'up' : 'down'}`}
              size={16}
            />
          </>
        )}
      >
        <ul tabIndex={-1}>
          {Object.values(ThemeType).map((mode, index) => {
            const isActive = theme === mode;
            // Hide "System" option if the browser does not support media query prefers-color-scheme
            const shouldBeHidden
              = !isPreferedColorSchemeSupported() && mode === ThemeType.system;
            if (shouldBeHidden)
              return null;
            return (
              <li key={mode}>
                <button
                  type="button"
                  id={`popper-${mode}`}
                  className={classNames('popper__option', {
                    'popper__option--active': isActive,
                  })}
                  {...(index === 0 && { autoFocus: true })}
                  onClick={() => handleSwitch(mode)}
                >
                  <VpIcon
                    aria-hidden
                    name={getIconFromTheme(mode)}
                    size={20}
                  />
                  <span>
                    {t(
                      `GLOBAL.THEME.${mode.toUpperCase()}` as MessageKeys<
                        Messages,
                        | 'GLOBAL.THEME.DARK'
                        | 'GLOBAL.THEME.LIGHT'
                        | 'GLOBAL.THEME.SYSTEM'
                      >,
                    )}
                  </span>
                </button>
              </li>
            );
          })}
        </ul>
      </PopperComponent>
    )
  );
};
