import { useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';
import { useSelector } from 'react-redux';
import { LanguageSwitcher } from '../Header/LanguageSwitcher';
import { ThemeSwitcher } from '../ThemeSwitcher/ThemeSwitcher';
import { MainColumn } from '../commons/MainColumn/MainColumn';
import { Link } from '@/navigation';
import { Logo } from '~/components/commons/Logo/Logo';
import { isWindow } from '~/utils/utils';
import appConst from '~/const/appConst';
import { authenticatedSelector, userSelector } from '~/selectors/selectors';
import { isAuthenticatedAsMainUser } from '~/utils/user/user';

export const Footer = () => {
  const t = useTranslations();

  const w = isWindow() && window;

  const authenticated = useSelector(authenticatedSelector);
  const user = useSelector(userSelector);

  const [isDidomiReady, setIsDidomiReady] = useState(false);

  const openDidomiPreferences = () => {
    w && w.Didomi.preferences.show();
  };

  useEffect(() => {
    if (!w)
      return;
    w.didomiOnReady = w.didomiOnReady || [];
    w.didomiOnReady.push(() => {
      setIsDidomiReady(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <footer id="footer" className="footer" role="contentinfo">
      <MainColumn>
        <div className="footer__wrapper">
          <div className="footer__top">
            <div className="footer__logo">
              <Logo isBrand={false} />
            </div>
          </div>
          <div className="footer__main">
            <section>
              <h3 className="vp-body-s footer__title">
                {t('NAVIGATION.ABOUT')}
              </h3>
              <nav aria-label={t('NAVIGATION.ABOUT')} role="navigation">
                <ul>
                  <li>
                    <Link className="vp-link" href="/about">
                      {t('NAVIGATION.DISCOVER')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="vp-link"
                      href={appConst.staticRoutes.projects}
                    >
                      {t('NAVIGATION.PROJECT')}
                    </Link>
                  </li>
                </ul>
              </nav>
            </section>
            {isAuthenticatedAsMainUser(user, authenticated) && (
              <section>
                <h3 className="vp-body-s footer__title">
                  {t('NAVIGATION.HELP')}
                </h3>
                <nav aria-label={t('NAVIGATION.HELP')} role="navigation">
                  <ul>
                    <li>
                      <Link
                        className="vp-link"
                        href="/contact"
                        rel="noindex, nofollow"
                      >
                        {t('NAVIGATION.CONTACT')}
                      </Link>
                    </li>
                  </ul>
                </nav>
              </section>
            )}
            <section className="footer__switchers">
              <LanguageSwitcher />
              <span className="footer__switchers__divider" />
              <ThemeSwitcher />
            </section>
          </div>
          <div className="footer__bottom">
            <section>
              <nav aria-label={t('NAVIGATION.LEGAL_NOTICE')} role="navigation">
                <ul className="vp-caption">
                  <li>
                    <Link className="vp-link vp-link--small" href="/sitemap">
                      {t('NAVIGATION.SITEMAP')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="vp-link vp-link--small"
                      href="/legal-informations"
                    >
                      {t('NAVIGATION.LEGAL_NOTICE')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="vp-link vp-link--small"
                      href="/general-terms-of-service"
                    >
                      {t('NAVIGATION.GTS')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="vp-link vp-link--small"
                      href="/special-terms-of-service"
                    >
                      {t('NAVIGATION.STS')}
                    </Link>
                  </li>
                  <li>
                    <Link className="vp-link vp-link--small" href="/privacy">
                      {t('NAVIGATION.DATA_USE')}
                    </Link>
                  </li>
                  {isDidomiReady && (
                    <li>
                      <button
                        type="button"
                        className="vp-link vp-link--small"
                        onClick={openDidomiPreferences}
                      >
                        {t('NAVIGATION.COOKIES_MANAGEMENT')}
                      </button>
                    </li>
                  )}
                </ul>
              </nav>
            </section>
          </div>
        </div>
      </MainColumn>
    </footer>
  );
};
