import type { JSX } from 'react';
import type { SportType } from '~/model/SportType';

export enum OptionsStatusValueType {
  all = 'all',
  followed = 'followed',
  user = 'user',
  recommended = 'recommended',
}

export interface StatusFromUserType {
  key: string;
  value: number;
}

export interface OptionSportsType {
  id: number | number[];
  title: string;
  label: JSX.Element | string;
  mysports?: SportType[];
  value: string;
  isDisabled?: boolean;
  isFavourite?: boolean;
  alias?: string[];
}
