import { newUrlFromLocaleChange } from '~/components/Header/utils';
import { CookieName } from '~/const/appConst';
import { AcceptLanguage } from '~/const/locale';
import type { UserType } from '~/model/UserType';
import { UserTypeEnum } from '~/model/UserType';
import { getLanguageFromLocale, setCookie } from '~/utils/utils';

export const isGuestUser = (user: UserType) =>
  user?.['@id'] && user?.['@type'] === UserTypeEnum.GUEST_USER;

export const isMainUser = (user: UserType) =>
  user?.['@id'] && user?.['@type'] === UserTypeEnum.USER;

export const isAuthenticated = (user: UserType, authenticated: boolean) =>
  !!authenticated && (isMainUser(user) || isGuestUser(user));

export const isAuthenticatedAsMainUser = (
  user: UserType,
  authenticated: boolean,
) => !!authenticated && isMainUser(user);

export const handleUserFavoriteLanguage = (
  user: UserType,
  locale: string,
  pathname: string,
) => {
  const language = getLanguageFromLocale(user?.favorite_locale);
  if (language && locale !== language) {
    setCookie(`NEXT_LOCALE=${language}`);
    setCookie(`${CookieName.COCREATION_LOCALE}=${user?.favorite_locale}`);
    window.location.replace(newUrlFromLocaleChange(AcceptLanguage[language], pathname));
  }
};
