export enum LocaleCode {
  de_DE = 'de',
  en_GB = 'en',
  es_ES = 'es',
  fr_FR = 'fr',
  it_IT = 'it',
  nl_BE = 'nl',
  pt_PT = 'pt',
}

export const defaultLocale = LocaleCode.fr_FR;

export const localeSubpaths: Record<AcceptLanguage, LocaleCode> = {
  de_DE: LocaleCode.de_DE,
  en_GB: LocaleCode.en_GB,
  es_ES: LocaleCode.es_ES,
  fr_FR: LocaleCode.fr_FR,
  it_IT: LocaleCode.it_IT,
  nl_BE: LocaleCode.nl_BE,
  pt_PT: LocaleCode.pt_PT,
};

export enum AcceptLanguage {
  de = 'de_DE',
  en = 'en_GB',
  es = 'es_ES',
  fr = 'fr_FR',
  it = 'it_IT',
  nl = 'nl_BE',
  pt = 'pt_PT',
}

export const labelLocales = {
  de_DE: 'Deutsch',
  en_GB: 'English',
  es_ES: 'Español',
  fr_FR: 'Français',
  it_IT: 'Italiano',
  nl_BE: 'Nederlands',
  pt_PT: 'Português',
};
