import { initialState } from './initialState';
import { actions } from '~/const/actions';

export const sports = (
  state: any = initialState.sports,
  action = { type: '', payload: null },
) => {
  if (action.type === actions.GET_SPORTS)
    return action.payload || state;

  return state;
};
