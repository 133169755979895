const matchMedia = {
  colorScheme: '(prefers-color-scheme)',
  dark: '(prefers-color-scheme: dark)',
  light: '(prefers-color-scheme: light)',
};
const narrowColumn = 'col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2';
const mediumColumn = 'col-12 col-md-10 offset-md-1';
const fullColumn = 'col-12';

export const semanticColor = (color: string) => {
  return `var(--vp-semantic-color-${color})`;
};

const indicatorSeparator = provided => ({
  ...provided,
  backgroundColor: 'transparent',
});

const dropdownIndicatorStyles = {
  color: semanticColor('content-neutral'),
};
const dropdownIndicator = provided => ({
  ...provided,
  ...dropdownIndicatorStyles,
});

const loadingMessage = provided => ({
  ...provided,
  textTransform: 'lowercase',
});

const menu = provided => ({
  ...provided,
  backgroundColor: semanticColor('background-main'),
  zIndex: 2,
});

const inputStyles = {
  color: semanticColor('content-neutral'),
};

const input = provided => ({
  ...provided,
  ...inputStyles,
});

const controlStyles = {
  backgroundColor: semanticColor('background-main'),
  borderColor: semanticColor('border-quiet'),
};

const noOptionsMessage = provided => ({
  ...provided,
  textTransform: 'lowercase',
});

const valueContainerStyles = {
  padding: '2px',
  paddingRight: `${32 / 16}rem`,
  paddingLeft: '4px',
};

const multiValueStyles = {
  backgroundColor: semanticColor('container-brand'),
  position: 'relative',
  height: `${32 / 16}rem`,
  lineHeight: `${32 / 16}rem`,
  margin: '1px',
  borderRadius: 'var(--vp-semantic-border-radius-full)',
};

const multiValueLabelStyles = {
  color: semanticColor('content-on-brand-neutral'),
  whiteSpace: 'nowrap',
  padding: `0 ${8 / 16}rem`,
  paddingLeft: '1rem',
  paddingRight: '0',
  fontSize: `${14 / 16}rem`, // 0.875rem = 14px
  fontWeight: 500,
};

const multiValueRemoveStyles = {
  whiteSpace: 'normal',
  padding: '0',
  width: `${32 / 16}rem`,
  display: 'flex',
  justifyContent: 'center',
  color: semanticColor('content-on-brand-neutral'),
  cursor: 'pointer',
  borderRadius: 'var(--vp-semantic-border-radius-full)',
  backgroundColor: semanticColor('container-brand'),
  ':hover': {
    color: semanticColor('content-on-brand-neutral'),
  },
};

const controlProfileStyles = {
  padding: '5px',
  borderColor: semanticColor('border-neutral'),
  borderRadius: 'var(--vp-semantic-border-radius-main)',
  ':hover': {
    boxShadow: `inset 0 0 0 var(--vp-semantic-border-width-m) ${semanticColor('border-neutral')}`,
  },
};

const selectStyles = {
  indicatorSeparator,
  dropdownIndicator,
  clearIndicator: dropdownIndicator,
  loadingMessage,
  menu,
  noOptionsMessage,
  input,
  control: provided => ({
    ...provided,
    ...controlStyles,
  }),
  multiValue: provided => ({
    ...provided,
    ...multiValueStyles,
  }),
  multiValueLabel: provided => ({
    ...provided,
    ...multiValueLabelStyles,
  }),
  multiValueRemove: provided => ({
    ...provided,
    ...multiValueRemoveStyles,
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    backgroundColor:
      (isSelected && semanticColor('background-alternative'))
      || (isFocused && semanticColor('background-alternative'))
      || semanticColor('background-main'),
    color: semanticColor('content-neutral'),
  }),
};

const sportsSelectStyles = {
  ...selectStyles,
  placeholder: provided => ({
    ...provided,
    marginLeft: 'var(--vp-semantic-spacing-s)',
    fontSize: `${14 / 16}rem`, // 0.875rem = 14px
    fontWeight: 500,
    color: semanticColor('content-quiet'),
  }),
  control: provided => ({
    ...provided,
    ...controlStyles,
    minHeight: `${40 / 16}rem`,
    borderRadius: 'var(--vp-semantic-border-radius-full)',
    border: 'var(--vp-semantic-border-width-none)',
    background: 'var(--vp-semantic-color-container-quiet)',
  }),
  input: provided => ({
    ...provided,
    ...inputStyles,
    marginLeft: `${8 / 16}rem`,
  }),
  valueContainer: provided => ({
    ...provided,
    ...valueContainerStyles,
    maxHeight: `${38 / 16}rem`,
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    flexWrap: 'nowrap',
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    fontSize: `${14 / 16}rem`, // 0.875rem = 14px
    gap: 'var(--vp-semantic-spacing-xs)',
    fontWeight: 500,
    backgroundColor:
      (isSelected && semanticColor('background-alternative'))
      || (isFocused && semanticColor('background-alternative'))
      || semanticColor('background-main'),
    color:
      (isSelected && semanticColor('content-brand'))
      || semanticColor('content-neutral'),
  }),
};

export const getProfileSelectStyles = () => {
  return {
    indicatorSeparator,
    dropdownIndicator,
    clearIndicator: dropdownIndicator,
    loadingMessage,
    menu,
    input,
    control: provided => ({
      ...provided,
      ...controlStyles,
      ...controlProfileStyles,
    }),
    singleValue: provided => ({
      ...provided,
      color: semanticColor('content-neutral'),
    }),
    valueContainer: provided => ({
      ...provided,
      ...valueContainerStyles,
    }),
    multiValue: provided => ({
      ...provided,
      ...multiValueStyles,
    }),
    multiValueLabel: provided => ({
      ...provided,
      ...multiValueLabelStyles,
    }),
    multiValueRemove: provided => ({
      ...provided,
      ...multiValueRemoveStyles,
    }),
    noOptionsMessage,
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        display: 'flex',
        alignItems: 'center',
        gap: 'var(--vp-semantic-spacing-xs)',
        backgroundColor:
          (isSelected && semanticColor('background-alternative'))
          || (isFocused && semanticColor('background-alternative'))
          || semanticColor('background-main'),
        color:
          (isSelected && semanticColor('content-brand'))
          || semanticColor('content-neutral'),
      };
    },
  };
};

const profileSelectStyles = getProfileSelectStyles();

const profileSportSelectStyles = {
  ...profileSelectStyles,
  control: provided => ({
    ...provided,
    ...controlStyles,
    ...controlProfileStyles,
    minHeight: `${40 / 16}rem`,
  }),
  multiValueLabel: provided => ({
    ...provided,
    ...multiValueLabelStyles,
    fontSize: '16px',
  }),
  clearIndicator: (provided, state) =>
    state.isMulti
      ? {
          ...provided,
          display: 'none',
        }
      : null,
};

const layout = {
  matchMedia,
  fullColumn,
  mediumColumn,
  narrowColumn,
  selectStyles,
  sportsSelectStyles,
  profileSelectStyles,
  profileSportSelectStyles,
};

export default layout;
