import type { AxiosError } from 'axios';
import axios from 'axios';
import type { Store } from '@reduxjs/toolkit';
import { getCookie } from './utils';
import {
  getAuthenticated,
  getAuthenticationRefresh,
} from '~/actions/actionsAccount';
import { actionsMaintenance } from '~/actions/actionsMaintenance';
import { apiConnect } from '~/api/apiConnect';
import { getResponseData } from '~/api/common/callApi';
import { CookieName } from '~/const/appConst';

// Set the refresh token status
let isAlreadyFetchingAccessToken = false;
// Use to store each request to retry after refresh (waiting list)
let subscribers = [];

// To trigger after token has been refreshed
const onRefreshTokenFetched = (access_token) => {
  subscribers = subscribers.filter(callback => callback(access_token));
};

// Used to add axios promises to subscribers list
const addSubscriber = (callback) => {
  subscribers.push(callback);
};

const setMaintenance = (store: Store) => {
  const { dispatch } = store;
  dispatch(actionsMaintenance.setMaintenanceMode(true));
};

const checkRefreshTokenError = (error: AxiosError) => {
  if (error?.response?.config?.url.includes('refresh-token')) {
    apiConnect.clearCredentials();
  }
  return Promise.reject(error);
};

const refreshTokenCall = async (originalRequest, store) => {
  const { dispatch } = store;
  isAlreadyFetchingAccessToken = true;
  try {
    const response = await apiConnect.refreshUserToken();
    isAlreadyFetchingAccessToken = false;
    const { access_token } = getResponseData(response);
    originalRequest.headers.Authorization = `Bearer ${access_token}`;
    // dispatch actions to redux store
    const isAuthenticated = !!response;
    // update "authenticated" and "authenticationRefresh" reducer
    dispatch(getAuthenticated(isAuthenticated));

    if (access_token) {
      dispatch(getAuthenticationRefresh(new Date()));
    }

    onRefreshTokenFetched(access_token);
  } catch (error) {
    apiConnect.clearCredentials();
  }
};

const refreshToken = (error: AxiosError, store: Store) => {
  const { config: originalRequest } = error;
  // Store Token from request headers Authorization Bearer
  const authorization = originalRequest?.headers.Authorization as string;
  const tokenFromRequest = authorization?.replace('Bearer ', '');
  // Store its validity
  const isTokenExpired = apiConnect.isTokenExpired(tokenFromRequest);
  // Store if the request is a refresh-token
  const isRefreshTokenRequest = originalRequest?.url?.includes('refresh-token');
  const isRefreshTokenExpired = apiConnect.isTokenExpired(getCookie(CookieName.REFRESH_TOKEN));
  // if the token of the request is expired
  // and is not a refresh token request
  if ((tokenFromRequest || !isRefreshTokenExpired) && !!isTokenExpired && !isRefreshTokenRequest) {
    // if refresh token is not currently fetched
    // execute the refresh
    if (!isAlreadyFetchingAccessToken)
      refreshTokenCall(originalRequest, store);

    // if refresh token is currently fetched
    // replace the expired token and retry the original request
    return new Promise((resolve) => {
      addSubscriber((access_token) => {
        originalRequest.headers.Authorization = `Bearer ${access_token}`;
        resolve(axios(originalRequest));
      });
    });
  }
  // If the error is coming from the refresh-token route
  // check if
  if (isRefreshTokenRequest)
    checkRefreshTokenError(error);
  return Promise.reject(error);
};

export const axiosInterceptorResponseError = (
  error: AxiosError,
  store: Store,
) => {
  switch (error.response && error.response.status) {
    case 502:
    case 503: {
      setMaintenance(store);
      return error;
    }
    case 401:
      return refreshToken(error, store);
    default:
      return checkRefreshTokenError(error);
  }
};

export const axiosInterceptorResponse = response => response;

export class AxiosProvider {
  private static _instance: AxiosProvider;
  private setupDone = false;

  public setup(store) {
    if (!this.setupDone) {
      this.setupDone = true;
      axios.interceptors.response.use(axiosInterceptorResponse, error =>
        axiosInterceptorResponseError(error, store));
    }
  }

  public static get Instance(): AxiosProvider {
    return this._instance || (this._instance = new this());
  }
}

const axiosInterceptors = {
  setupInterceptors: (store) => {
    // Add a response interceptor
    axios.interceptors.response.use(axiosInterceptorResponse, error =>
      axiosInterceptorResponseError(error, store));
  },
};

export default axiosInterceptors;
