import { useTranslations } from 'next-intl';
import { Link } from '@/navigation';

export const SkipLink = () => {
  const t = useTranslations();
  return (
    <Link className="skip-links" href="#maincontent">
      {t('NAVIGATION.SKIP')}
    </Link>
  );
};
