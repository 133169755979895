import type { CSSProperties, HTMLAttributes, PropsWithChildren,
} from 'react';
import classNames from 'classnames';
import { VtmnLoader } from '@vtmn/react';

interface ILoaderProps {
  size?: 'small' | 'medium' | 'large';
  overlay?: boolean;
  loading?: boolean;
  centered?: boolean;
  inverted?: boolean;
  style?: CSSProperties;
}

export const Loader = ({
  size = 'small',
  loading = true,
  children,
  centered = false,
  inverted = false,
  style,
  overlay = false,
}: PropsWithChildren<ILoaderProps>) => {
  const styles = style && { style: { ...style } };
  return (
    <div
      data-testid="loader"
      className={classNames('loader', {
        'loader--overlay': overlay,
        'loader--inverted': inverted,
        'loader--centered': centered,
        'loader--nesting': children,
        'loader--loading': loading,
      })}
      {...(styles as HTMLAttributes<HTMLDivElement>)}
    >
      {children && (
        <div className="loader__nested-content">
          {children}
        </div>
      )}
      {loading && (
        <span data-testid="PageLoader" className="loader__icon">
          <VtmnLoader size={size} />
        </span>
      )}
    </div>
  );
};

export const MainLoader = () => (
  <Loader centered size="medium" style={{ padding: '2rem' }} />
);
