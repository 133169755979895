import type { Action } from '@reduxjs/toolkit';
import type { ThunkDispatch } from 'redux-thunk';
import type { LocaleCode } from '~/const/locale';
import type { ReduxStoreType } from '~/reducers/model';

export type UseDispatchType = ThunkDispatch<ReduxStoreType, any, Action>;

export enum ScrollMemorisation {
  home = 'home',
  projects = 'projects',
}

export enum ThemeType {
  light = 'light',
  dark = 'dark',
  system = 'system',
}

export type DateType = string; // YYYY-MM-DDThh:mm:ss

export type UriType = string; // /api/criteria/263

export type UrlType = string; // https://storage.googleapis.com/media-local-wm-hvhi/...

export type LocaleType = keyof typeof LocaleCode; // en_GB, fr_FR, or en-GB

export type LanguageType = LocaleCode; // en, fr, it...

export type ContentType = string; // image/png, image/webp
