import { useEffect } from 'react';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import type { UserType } from '~/model/UserType';
import { dataLayerPush } from '~/utils/analytics/dataLayerPush';
import { AnalyticEvents } from '~/const/appConst';
import { usePathname } from '@/navigation';

export const usePageView = (user: UserType, userLanguage: string) => {
  const searchParams = useSearchParams();
  const router = useRouter();
  const pathname = usePathname();

  useEffect(() => {
    if (router.pathname) {
      dataLayerPush({
        page_type: router.pathname,
        page_name: pathname,
        page_language: userLanguage,
        user_id: user?.id?.toString(),
        user_login_status: !!user?.id,
        user_sport: user?.sports?.map(sport => sport.label).toString(),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    router.pathname,
    pathname,
    searchParams,
    user?.id,
    userLanguage,
  ]);

  useEffect(() => {
    if (user?.id && user?.term_of_use_accepted === false) {
      dataLayerPush({
        event: AnalyticEvents.SIGN_UP,
        user_id: user?.id?.toString(),
      });
    }
  }, [user?.id]);
};
